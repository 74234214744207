// Chakra imports
import { Flex, Text } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import React from 'react';

import Content from './Content';
import { DocumentPreconfiguration } from 'types/Template';
import preConfigModule from 'assets/img/modules/pre-config.png';
type ComponentToPrintProps = {
  bgButton: string;
  bgFocus: any;
  bgHover: any;
  title: string;
  desc: string;
  selectedDocument: DocumentPreconfiguration;
  [x: string]: any;
};
export default class ComponentToPrint extends React.Component<ComponentToPrintProps> {
  // for react-to-print to work, it must be called from a class based component
  render() {
    const {
      bgButton,
      bgFocus,
      bgHover,
      title,
      desc,
      selectedDocument,
      ...rest
    } = this.props;

    return (
      <Card
        {...rest}
        justifySelf="center"
        alignSelf="flex-start"
        m="10px"
        my="0px"
        p="24px"
      >
        <Card
          bgGradient={`linear(to-b, rgba(37, 1, 186, 0.8), rgba(37, 1, 186, 0.6)), url(${preConfigModule})`}
          backgroundRepeat="no-repeat"
          bgSize="cover"
          bgPosition="10%"
          flexDirection="row"
          p={{ base: '20px', md: '50px' }}
          py="50px"
          borderRadius="20px"
        >
          <Flex direction="column" color="white" h="100%" w="100%">
            <Text
              lineHeight="100%"
              fontSize={{ sm: 'lg', md: '30px', lg: '36px', xl: '40px' }}
              fontWeight="bold"
            >
              {title}
            </Text>
            <Text fontSize={{ base: 'md', md: 'xl' }} fontWeight="regular">
              {desc}
            </Text>
          </Flex>
         {/*  <Flex alignSelf="start" my={{ base: 'auto', md: '0px' }}>
            <Button
              alignItems="center"
              me="10px"
              justifyContent="center"
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              p="7px"
              minW="unset"
              h="32px"
              lineHeight="100%"
              borderRadius="10px"
            >
              <Icon as={MdDownload} color="white" w="18px" h="18px" />
            </Button>
            <Button
              alignItems="center"
              justifyContent="center"
              bg="linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)"
              _hover={{
                bg: 'linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)',
              }}
              _focus={{
                bg: 'linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)',
              }}
              _active={{
                bg: 'linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)',
              }}
              p="7px"
              minW="unset"
              h="32px"
              lineHeight="100%"
              borderRadius="10px"
            >
              <Icon as={MdShare} color="white" w="18px" h="18px" />
            </Button>
          </Flex> */}
        </Card>
        <Content selectedDocument={selectedDocument} />
      </Card>
    );
  }
}
