// Chakra imports
import { Box, Button, Flex, Icon } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import useAuth from 'hooks/useAuth';
import { Sheets } from './Sheets';
import { HeaderSheet } from 'types/Template';
import { useEffect, useRef, useState } from 'react';
import { FaExpandAlt } from 'react-icons/fa';

export default function CourseInfo(props: {
  cod_project: number;
  cod_module: number;
  name_module: string;
  title: string;
  desc: string
  headersModule?: (details: HeaderSheet[]) => void;
  [x: string]: any;
}) {
  const { cod_project, cod_module, name_module, headersModule, title, desc } = props;
  let { user } = useAuth();

  const [isFullscreenModule, setIsFullscreenModule] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  // Vigilar el cambio de modo pantalla completa
  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreenModule(Boolean(document.fullscreenElement));
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () =>
      document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  const toggleFullscreen = () => {
    if (!isFullscreenModule) {
      if (cardRef.current?.requestFullscreen) {
        cardRef.current.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  return (
    <>
      <Card
        mt="20px"
        p={{ base: '20px', md: '20px' }}
        ref={cardRef} // Attach ref here
        position="relative"
        overflow="hidden"
        marginInlineEnd={0}
      >
        <Box w="100%" mb="0px">
          <Flex direction={{ base: 'column', '3xl': 'column' }}>
            <Sheets
              codProject={cod_project}
              codModule={cod_module}
              nameModule={name_module}
              rolUser={user?.role_name}
              headersModule={headersModule}
              cardRef={cardRef}
              isFullscreenModule={isFullscreenModule}
              title={title}
              desc={desc}
            />
          </Flex>
        </Box>
        <Button
          position="absolute"
          top="-5px"
          right="-5px"
          float='left'
          onClick={toggleFullscreen}
          zIndex="1"
        >
          <Icon ms="auto" as={FaExpandAlt} color="green.500" h="18px" w="18px" />
        </Button>
      </Card>
    </>
  );
}
