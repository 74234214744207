import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  Image,
  Link,
  Divider,
  Icon,
  VStack,
} from '@chakra-ui/react';
import { FaQuoteRight } from 'react-icons/fa';
import TestimonialImage01 from '../../../assets/img/landing/testimonial-01.jpg';
import TestimonialImage02 from '../../../assets/img/landing/testimonial-02.jpg';
import TestimonialImage03 from '../../../assets/img/landing/testimonial-03.jpg';

export default function Testimonials() {
  return (
    <Box
      as="section"
      py={{ base: 12, md: 20 }}
      id="testimonials"
    >
      <Box maxW="6xl" mx="auto" px={{ base: 4, sm: 6 }}>
        {/* Section header */}
        <Box maxW="3xl" mx="auto" textAlign="center" pb={{ base: 12, md: 20 }}>
          <Heading as="h2" size="xl" mb={4}>
            No solo confíes en nuestra palabra
          </Heading>
          <Text fontSize="xl" color="gray.400">
            Descubre lo que nuestros clientes tienen que decir sobre cómo HR
            Smart ha transformado la gestión de sus recursos humanos. Lee sus
            experiencias y testimonios para ver cómo podemos ayudarte.
          </Text>
        </Box>

        {/* Testimonials */}
        <Grid
          templateColumns={{ base: '1fr', lg: 'repeat(3, 1fr)' }}
          gap={{ base: 8, lg: 6 }}
          mx="auto"
        >
          {/* 1st testimonial */}
          <TestimonialCard
            imageSrc={TestimonialImage01}
            altText="Testimonial 01"
            quote="hr smart ha simplificado nuestra gestión de recursos humanos de manera increíble. Las plantillas preconfiguradas nos ahorraron horas de trabajo y la integración centralizada ha hecho que la administración sea mucho más eficiente."
            name="María Gómez"
            role="Directora de RRHH"
            delay={100}
          />

          {/* 2nd testimonial */}
          <TestimonialCard
            imageSrc={TestimonialImage02}
            altText="Testimonial 02"
            quote="Desde que implementamos hr smart, hemos visto una mejora significativa en la eficiencia de nuestros procesos. La automatización de tareas y la gestión centralizada nos han permitido enfocarnos en lo que realmente importa."
            name="Carlos Martínez"
            role="Coordinador de Proyectos"
            delay={200}
          />

          {/* 3rd testimonial */}
          <TestimonialCard
            imageSrc={TestimonialImage03}
            altText="Testimonial 03"
            quote="hr smart ha sido una herramienta invaluable para nuestro equipo. La capacidad de automatizar tareas repetitivas y gestionar todos nuestros procesos en un solo lugar ha transformado nuestra manera de trabajar."
            name="Andres Fernández"
            role="Gerente de Operaciones"
            delay={400}
          />
        </Grid>
      </Box>
    </Box>
  );
}

interface Testimonial {
  imageSrc: string;
  altText: string;
  quote: string;
  name: string;
  role: string;
  delay?: number;
}
function TestimonialCard({
  imageSrc,
  altText,
  quote,
  name,
  role,
  delay,
}: Testimonial) {
  return (
    <VStack
      spacing={6}
      p={6}
      bg="lightbranding.100"
      align="start"
      data-aos="fade-up"
      data-aos-delay={delay || 0}
      borderRadius="md"
    >
      <Flex position="relative">
        <Image
          borderRadius="full"
          src={imageSrc}
          boxSize="48px"
          alt={altText}
        />
        <Icon
          as={FaQuoteRight}
          position="absolute"
          top="0"
          right="-12px"
          w={6}
          h={5}
          color="lightbranding.50"
        />
      </Flex>
      <Text fontSize="lg" color="darkbrading.500" flex="1">
        {quote}
      </Text>
      <Divider borderColor="gray.700" />
      <Text fontSize="md" fontWeight="medium">
        <Text as="cite" color="darkbrading.500" fontStyle="normal">
          {name}
        </Text>{' '}
        -{' '}
        <Link href="#" color="darkbrading.500" _hover={{ color: 'darkbrading.400' }}>
          {role}
        </Link>
      </Text>
    </VStack>
  );
}
