import {
  Box,
  Button,
  VStack,
  HStack,
  Text,
  Flex,
  useColorModeValue,
  Grid,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getCompaniesByCustomers } from '../../services/Company';
import { Companies } from '../../types/Company';
import { getAllUsersCompany } from '../../services/User';
import { FaFileAlt, FaUpload } from 'react-icons/fa';
import Card from 'components/card/Card';
import { Users } from 'types/Auth';
import patterHR from 'assets/img/landing/pattern.svg';
import { ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons';
import CompanyInfo from './components/CompanyInfo';

import RegisterCompany from './components/RegisterCompany';
import RegisterUser from './components/RegisterUser';
import useAuth from 'hooks/useAuth';

const AdminPanel = () => {
  const [view, setView] = useState<'register' | 'company'>('register');
  const [companyCustomer, setCompanyCustomer] = useState<Companies[]>([]);

  const [selectedUsersCompany, setSelectedUsersCompany] = useState<Users[]>([]);

  const [selectedCompany, setSelectedCompany] = useState<Companies>();
  /* const [templateUser, setTemplateUser] = useState<TemplateData[]>([]);

  const [isLoading, setIsLoading] = useState(true); */

  const { user } = useAuth();

  useEffect(() => {
    if (user.role_name === 'superadmin') {
      if (view === 'register') {
        getAllCompanies();
      }
    } else {
      getAllCompanies();
      allUsersCompany(user.name_company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const getAllCompanies = async () => {
    try {
      const { data } = await getCompaniesByCustomers();
      setCompanyCustomer(data);
      if (user.role_name === 'admin') {
        setSelectedCompany(data[0]);
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const textTd = useColorModeValue('#F2EFFF', '#7551FF');

  const {
    isOpen: isOpenCustomer,
    onOpen: onOpenCustomer,
    onClose: onCloseCustomer,
  } = useDisclosure();

  const handleSelectCompany = (company: Companies) => {
    setSelectedCompany(company);
  };

  const allUsersCompany = async (nameCompany: string) => {
    try {
      const { data } = await getAllUsersCompany(nameCompany);
      setSelectedUsersCompany(data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  /* const infoTemplatesCustomer = async (company: string) => {
    try {
      let { data } = await getTemplatesCustomer(company);
      setTemplateUser(data);
    } catch (error) {
      console.error('Error fetching user templates:', error);
    } finally {
      if (isLoading) setIsLoading(false);
    }
  }; */

  const handleConfirmSelection = () => {
    if (selectedCompany) {
      allUsersCompany(selectedCompany?.name);
      onCloseCustomer();
    } else {
      console.log('No company selected');
    }
  };

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Grid
          mb="20px"
          gridTemplateColumns={{ xl: 'repeat(1, 1fr)', '2xl': '1fr' }}
          gap={{ base: '20px', xl: '20px' }}
          display={{ base: 'block', xl: 'grid' }}
        >
          <Flex flexDirection="column">
            <Flex
              direction="column"
              background={`linear-gradient(to bottom, #b2aaf9, #b2aaf9)`}
              bgSize="cover"
              position="relative"
              py={{ base: '30px', md: '56px' }}
              px={{ base: '30px', md: '64px' }}
              borderRadius="30px"
            >
              <Box
                _before={{
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${patterHR})`,
                  backgroundSize: '100%',
                  backgroundRepeat: 'repeat',
                  backgroundPosition: 'center',
                  opacity: 0.2,
                  zIndex: 0,
                }}
              />
              {user.role_name === 'superadmin' && (
                <>
                  <Text
                    fontSize={{ base: '24px', md: '34px' }}
                    color="white"
                    mb="14px"
                    maxW={{
                      base: '100%',
                      md: '64%',
                      lg: '46%',
                      xl: '70%',
                      '2xl': '50%',
                      '3xl': '42%',
                    }}
                    fontWeight="700"
                    lineHeight={{ base: '32px', md: '42px' }}
                  >
                    Administre las compañias y los usuarios.
                  </Text>
                  <Flex align="center">
                    <Button
                      bg="white"
                      color="black"
                      _hover={{ bg: 'whiteAlpha.900' }}
                      _active={{ bg: 'white' }}
                      _focus={{ bg: 'white' }}
                      fontWeight="500"
                      fontSize="14px"
                      py="20px"
                      px="27"
                      me="38px"
                      onClick={onOpenCustomer}
                    >
                      Seleccione una compañia
                    </Button>
                  </Flex>
                </>
              )}
              {user.role_name === 'admin' && (
                <>
                  <Text
                    fontSize={{ base: '24px', md: '34px' }}
                    color="white"
                    mb="14px"
                    maxW={{
                      base: '100%',
                      md: '64%',
                      lg: '46%',
                      xl: '70%',
                      '2xl': '50%',
                      '3xl': '42%',
                    }}
                    fontWeight="700"
                    lineHeight={{ base: '32px', md: '42px' }}
                  >
                    Administre su compañia y los usuarios.
                  </Text>
                </>
              )}
            </Flex>
          </Flex>
        </Grid>
        <Grid
          templateColumns={{
            base: '1fr',
            lg: '3fr 2fr',
          }}
          templateRows={{
            base: 'repeat(1, 1fr)',
            lg: '1fr',
          }}
          gap={{ base: '20px', xl: '20px' }}
        >
          <CompanyInfo
            gridArea={{
              base: '1 / 1 / 2 / 2',
              lg: '1 / 1 / 2 / 2',
            }}
            banner={
              selectedCompany?.banner_url ??
              require('assets/img/auth/banner.png')
            }
            avatar={
              selectedCompany?.image_url ??
              require('assets/img/profile/Project1.png')
            }
            name={selectedCompany?.name ?? user.name_company ?? ''}
            description={
              selectedCompany?.description ?? 'Descripción no disponible'
            }
            tableData={selectedUsersCompany || []}
          />

          {user.role_name === 'superadmin' && (
            <Card
              gridArea={{
                base: '3 / 1 / 4 / 2',
                lg: '1 / 2 / 2 / 3',
              }}
            >
              <VStack spacing={8}>
                <HStack spacing={4} wrap="nowrap" justify="center">
                  <Button
                    margin={0}
                    colorScheme={view === 'register' ? 'brandScheme' : 'gray'}
                    onClick={() => setView('register')}
                    leftIcon={<FaUpload />}
                  >
                    Registrar usuario
                  </Button>
                  <Button
                    margin={0}
                    colorScheme={view === 'company' ? 'brandScheme' : 'gray'}
                    onClick={() => setView('company')}
                    leftIcon={<FaFileAlt />}
                  >
                    Crear compañías
                  </Button>
                </HStack>
                {view === 'register' && (
                  <RegisterUser companyCustomer={companyCustomer} />
                )}
                {view === 'company' && <RegisterCompany />}
              </VStack>
            </Card>
          )}
        </Grid>

        {/* {user.role_name === 'superadmin' && (
          <TemplateCompanyInfo
            selectedCompany={selectedCompany}
            rolUser={user.role_name}
            templateUser={templateUser}
          />
        )} */}
      </Box>
      <Modal isOpen={isOpenCustomer} onClose={onCloseCustomer} size="lg">
        <ModalOverlay />
        <ModalContent maxHeight="70vh">
          <ModalHeader>Compañias</ModalHeader>
          <ModalBody maxHeight="70vh" height="70vh" overflowY="auto">
            <Table style={{ minWidth: 'max-content', maxWidth: '600px' }}>
              <Tbody>
                {companyCustomer.map((company) => (
                  <Tr
                    key={company.cod_company}
                    onClick={() => handleSelectCompany(company)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor:
                        selectedCompany?.cod_company === company.cod_company
                          ? textTd
                          : 'transparent',
                    }}
                  >
                    <Td
                      style={{
                        border: '2px solid #b5b2b2',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {company.name}
                      {selectedCompany?.cod_company === company.cod_company ? (
                        <Button
                          colorScheme="blue"
                          size="sm"
                          leftIcon={<CheckIcon />}
                        >
                          Seleccionado
                        </Button>
                      ) : (
                        <Button
                          colorScheme="gray"
                          size="sm"
                          leftIcon={<ArrowForwardIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectCompany(company);
                          }}
                        >
                          Seleccionar
                        </Button>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={onCloseCustomer}>
              Cancelar
            </Button>
            <Button colorScheme="blue" ml={3} onClick={handleConfirmSelection}>
              Selecciona
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AdminPanel;
