import { Box, Heading, Text, Flex, Icon, SimpleGrid } from '@chakra-ui/react';
import {
  FaTasks,
  FaCog,
  FaExpand,
  FaTabletAlt,
} from 'react-icons/fa';

export default function Features() {
  return (
    <Box
      as="section"
      py={{ base: '12', md: '20' }}
      maxW="6xl"
      mx="auto"
      px={{ base: '4', sm: '6' }}
      id="services"
    >
      <Box
        textAlign="center"
        maxW="3xl"
        mx="auto"
        pb={{ base: '12', md: '20' }}
      >
        <Heading as="h2" size="xl" mb="4">
          Características principales
        </Heading>
        <Text fontSize="xl" color="gray.400">
          Solución preconfigurada​ Automatiza la carga de trabajo con plantillas
          predefinidas, diseñadas para adaptarse a las necesidades de la
          organización. Reduce el tiempo de configuración y evita errores
          comunes. ​
        </Text>
      </Box>

      {/* Grid de Items */}
      <SimpleGrid
        columns={{ base: 1, md: 1, lg: 1 }}
        spacing={{ base: 1, lg: 1 }}
        maxW={{ md: '800', lg: '800' }}
        mx="auto"
        mb={10}
      >
        <Flex direction="column" align="center" textAlign="center">
          <Icon
            as={FaTabletAlt}
            w={16}
            h={16}
            mb="4"
            color="lightbranding.600"
          />
          <Heading as="h4" size="md" mb="2">
            Solución preconfigurada
          </Heading>
          <Text fontSize="lg" color="gray.400">
            Automatiza la carga de trabajo con plantillas predefinidas,
            diseñadas para adaptarse a las necesidades de la organización.
            Reduce el tiempo de configuración y evita errores comunes.
          </Text>
          <Text mt="2" fontSize="lg" color="gray.400">
            Plantillas predefinidas:
          </Text>
          <ul
            style={{
              listStyleType: 'circle',
              color: 'gray.600',
              textAlign: 'left'
            }}
          >
            <li>
              <Text fontSize="lg" color="gray.400">
                <b>Gestión smart:</b> Empleado Central, Reclutamiento e
                Incorporación{' '}
              </Text>
            </li>
            <li>
              {' '}
              <Text fontSize="lg" color="gray.400">
              <b>Desarrollo smart:</b> Capacitación y Sucesión & Desarrollo{' '}
              </Text>
            </li>
            <li>
              {' '}
              <Text fontSize="lg" color="gray.400">
              <b>Evaluación smart:</b> Compensación y Objetivos & Desempeño{' '}
              </Text>
            </li>
          </ul>
        </Flex>

      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 3, lg: 3 }}
        spacing={{ base: 3, lg: 3 }}
        maxW={{ md: '2xl', lg: 'none' }}
        mx="auto"
      >
        
        <Flex direction="column" align="center" textAlign="center">
          <Icon as={FaTasks} w={16} h={16} mb="4" color="lightbranding.600" />
          <Heading as="h4" size="md" mb="2">
            Gestión Centralizada
          </Heading>
          <Text fontSize="lg" color="gray.400">
            Gestiona todos tus procesos de talento humano desde un solo lugar.
            Centraliza tu trabajo y optimiza la administración. ​
          </Text>
        </Flex>

        <Flex direction="column" align="center" textAlign="center">
          <Icon as={FaCog} w={16} h={16} mb="4" color="lightbranding.600" />
          <Heading as="h4" size="md" mb="2">
            Automatización de Tareas
          </Heading>
          <Text fontSize="lg" color="gray.400">
            Accede a todas tus herramientas desde una única plataforma. Puedes
            gestionar todos los procesos de talento humano sin necesidad de
            cambiar de entorno. ​
          </Text>
        </Flex>

        <Flex direction="column" align="center" textAlign="center">
          <Icon as={FaExpand} w={16} h={16} mb="4" color="lightbranding.600" />
          <Heading as="h4" size="md" mb="2">
            Escalabilidad de la solución
          </Heading>
          <Text fontSize="lg" color="gray.400">
            Sistema que es altamente escalable, permitiendo agregar
            funcionalidades conforme el negocio crece. ​
          </Text>
        </Flex>
      </SimpleGrid>
    </Box>
    
  );
}
