import { Box, Grid, IconButton, useDisclosure } from '@chakra-ui/react';

// Custom components
import CourseInfo from './components/CourseInfo';
import Completion from './components/Completion';
import { useLocation } from 'react-router-dom';
import { CloseIcon, InfoIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { HeaderSheet } from 'types/Template';
// Assets
export default function ConfigDetailModule() {
  // Chakra Color Mode
  const location = useLocation();
  const { cod_project, cod_module, title, desc, nameModule, titleModule } =
    location.state || {};

  const [isBoxVisible, setIsBoxVisible] = useState(true);
  const { onOpen, onClose } = useDisclosure();

  const handleToggle = () => {
    setIsBoxVisible(!isBoxVisible);
  };

  const [headersDetail, setHeadersDetail] = useState<HeaderSheet[]>([]);

  const handleHeadersModule = (templateUserDetails: HeaderSheet[]) => {
    setHeadersDetail(templateUserDetails);
  };

  return (
    <Box
      maxW="100%"
      display="flex"
      flexDirection="column"
      mt={{ base: '20px', sm: '100px', md: '80px', xl: '80px' }}
    >
      <Grid
        templateColumns={isBoxVisible ? '2fr 0.6fr' : '3fr 0.1fr'}
        gap={2}
        display={{ base: 'block', lg: 'grid' }}
        mb={{ base: '20px', lg: '0px' }}
      >
        <Box
          gridArea="1 / 1 / 2 / 2"
          me={{ lg: '10px' }}
          mb={{ base: '20px', lg: '0px' }}
        >
          <CourseInfo
            title={title}
            desc={desc}
            cod_project={cod_project}
            cod_module={cod_module}
            name_module={nameModule}
            headersModule={handleHeadersModule}
          />
        </Box>
        <Box position="relative">
          {isBoxVisible ? (
            <Box gridArea="1 / 2 / 2 / 3" p={4} borderRadius="md">
              <Completion
                cod_project={cod_project}
                cod_module={cod_module}
                name_module={nameModule}
                headersModule={headersDetail}
                titleModule={titleModule}
              />
              <IconButton
                aria-label="Close"
                icon={<CloseIcon />}
                position="absolute"
                top={2}
                right={2}
                onClick={() => {
                  handleToggle();
                  onOpen();
                }}
              />
            </Box>
          ) : (
            <IconButton
              aria-label="Show Info"
              icon={<InfoIcon />}
              onClick={() => {
                handleToggle();
                onClose();
              }}
            />
          )}
        </Box>
      </Grid>
    </Box>
  );
}
