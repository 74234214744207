import { Box, Heading, Text, Image, Grid, useColorModeValue } from '@chakra-ui/react';
import FeatImage01 from '../../../assets/img/zigzag/feat-image-1.png';
import FeatImage02 from '../../../assets/img/zigzag/feat-image-2.png';
import FeatImage03 from '../../../assets/img/zigzag/feat-image-3.png';
import FeatImage04 from '../../../assets/img/zigzag/feat-image-4.png';
import FeatImage05 from '../../../assets/img/zigzag/feat-image-5.png';

interface FeatureItemProps {
  imageSrc: string;
  altText: string;
  title: string;
  subtitle: string;
  description: string;
  reverse?: boolean;
}

const FeatureItem: React.FC<FeatureItemProps> = ({
  imageSrc,
  altText,
  title,
  subtitle,
  description,
  reverse,
}) => {
  const bgCard = useColorModeValue('darkbrading.50', 'lightbranding.100');
  const textCard = useColorModeValue('gray.400', 'darkbrading.500');
  
  return (
    <Grid
      templateColumns={{
        lg: 'repeat(12, 1fr)',
        sm: 'repeat(2, 1fr)',
        base: '1fr',
      }}
      gap={6}
      alignItems="center"
      flexDirection={reverse ? 'row-reverse' : 'row'}

      position="relative"
      overflow="hidden"
    >
      {/* Image Section */}
      <Box
        gridColumn={['1 / -1', 'span 6']}
        order={[1, 1, reverse ? 2 : 1]}
        display="flex"
        justifyContent="center"
        position="relative"
        zIndex={4}
      >
        <Image
          src={imageSrc}
          alt={altText}
          width="auto"
          height={300}
          borderRadius="md"
          objectFit="cover"
        />
      </Box>

      {/* Content Section */}
      <Box
        gridColumn={['1 / -1', 'span 6']}
        
        bg={bgCard}
        p={3}
        boxShadow="xl"
        position="relative"
        zIndex={2}
        height="min-content"
        width="fit-content"
        borderRadius='20'
        sx={{
          '@media screen and (min-width: 1024px)': { // Solo afecta en resoluciones lg o más
            order:[2, 2, reverse ? 1 : 2],
            transform: reverse ? 'translateX(230px)' : 'translateX(-230px)',
            paddingLeft: reverse ? '15px' : '90px',
            paddingRight: reverse ? '90px' : '15px',
            borderRadius: reverse ? '20px 5px 5px 20px' : '5px 20px 20px 5px'
          },
        }}
      >
        <Box mb={4}>
          <Text
            fontSize="lg"
            fontWeight="bold"
            color="darkbrading.500"
            bg="lightbranding.50"
            display="inline-block"
            py="2px"
            px={3}
            borderRadius="full"
            mb={4}
          >
            {subtitle}
          </Text>
          <Heading size="sm" mb={1} color="lightbranding.500">
            {title}
          </Heading>
          <Text fontSize="md" mb={1} color={textCard}>
            {description}
          </Text>
        </Box>
      </Box>
    </Grid>
  );
};

export default function Zigzag() {
  return (
    <Box maxW="6xl" mx="auto" px={[4, 6]} py={[12, 20]} id="benefist">
      <Box textAlign="center" mb={12}>
        <Text
          fontSize="sm"
          fontWeight="bold"
          bg="lightbranding.50"
          display="inline-block"
          py={1}
          px={3}
          borderRadius="full"
          mb={4}
        >
          Alcanza objetivos que importan
        </Text>
        <Heading as="h1" size="xl" mb={4}>
          Beneficios por utilizar hr smart
        </Heading>
      </Box>

      <Grid gap={20}>
        <FeatureItem
          imageSrc={FeatImage01}
          altText="Features 01"
          title="Reducir el costo de adquisición del sistema de automatización ​"
          subtitle="Hacia una inversión más inteligente."
          description="Al optar por un sistema de automatización, es fundamental minimizar los costos de adquisición sin comprometer la calidad. Una estrategia bien definida no solo permite ahorrar dinero, sino también maximizar el valor que se obtiene de la inversión."
        />

        <FeatureItem
          imageSrc={FeatImage02}
          altText="Features 02"
          title="Reducir el riesgo en el proceso de implementación"
          subtitle="Asegurando una transición suave y segura."
          description="La implementación de un nuevo sistema puede ser un proceso desafiante. Reducir el riesgo implica anticipar problemas y establecer medidas preventivas que aseguren una transición sin contratiempos."
          reverse
        />

        <FeatureItem
          imageSrc={FeatImage03}
          altText="Features 03"
          title="Acortar el tiempo de implementación con resultados eficientes"
          subtitle="Menos tiempo, más eficacia."
          description="La velocidad de implementación es crucial para el éxito de un sistema. Al optimizar procesos, se puede lograr una implementación rápida sin sacrificar la calidad de los resultados."
        />

        <FeatureItem
          imageSrc={FeatImage04}
          altText="Features 04"
          title="Maximizar el ROI de la adopción del sistema"
          subtitle="Rentabilidad que se traduce en éxito."
          description="Adoptar un nuevo sistema no solo es una inversión, sino una oportunidad para mejorar la rentabilidad. Maximizar el retorno sobre la inversión (ROI) implica medir y evaluar el impacto del sistema en el rendimiento general de la organización."
          reverse
        />

        <FeatureItem
          imageSrc={FeatImage05}
          altText="Features 05"
          title="Bajo costo del licenciamiento SAP SuccessFactors"
          subtitle="Acceso a soluciones premium a precios accesibles."
          description="La adopción de SAP SuccessFactors no tiene que ser un lujo. Con opciones de licenciamiento a bajo costo, las empresas pueden acceder a tecnología de vanguardia sin desbalancear su presupuesto."
        />
      </Grid>
    </Box>
  );
}
