import { useEffect, useState } from 'react';
import { Flex, Thead, useToast } from '@chakra-ui/react';
import { Table, Tbody, Td, Tr } from '@chakra-ui/react';
import {
  Config,
  DataField,
  FieldsUpdate,
  HeaderSheet,
  Role,
  RootObject,
  RolesUpdate,
} from '../../../../../../../types/Template';
import { getInfoSheetById } from '../../../../../../../services/Template';
import Row from './Rows';
import { getFieldsTemplateCustomer } from 'services/Admin';

type TableProps = {
  codSheet: number;
  onSectionFinish: () => void;
  isFinishTemplate: boolean;
  isFinishedSection: boolean;
  setFinishSection: any;
  setFinishSheet: (finalized: boolean) => void;
  nameModule: string;
  setFieldsUpdatedToTable: (fields: FieldsUpdate[]) => void;
  setRolesUpdatedToTable: (roles: RolesUpdate[]) => void;
  companyCustomer?: string;
  rolUser: string;
  codProject: number;
  codModule: number;
  cardRef: any;
  isFullscreenModule: boolean;
};

interface CombinedField {
  cod_id: number;
  combinedData: (DataField | Config)[];
  roles: Role[];
}

export const TableSheets = ({
  codSheet,
  codModule,
  codProject,
  isFinishedSection,
  nameModule,
  setFinishSheet,
  setFieldsUpdatedToTable,
  setRolesUpdatedToTable,
  companyCustomer,
  rolUser,
  cardRef,
  isFullscreenModule,
}: TableProps) => {
  const toast = useToast();
  const [headers, setHeaders] = useState<string[]>([]);
  const [openRow, setOpenRow] = useState<number | null>(null);
  const [transformedData, setTransformedData] = useState<CombinedField[]>([]);
  const [updatedFieldsData, setUpdatedFieldsData] = useState<FieldsUpdate[]>(
    [],
  );

  const [updatedRolesData, setUpdatedRolesData] = useState<RolesUpdate[]>([]);

  const infoSheetById = async (codSheet: number, nameModule: string) => {
    if (!codSheet) return;
    try {
      if (rolUser === 'superadmin') {
        if (nameModule !== 'lms') {
          /* TEMPLATES NOT LMS WITH SUPERADMIN */
          let templateUserData: RootObject = await getFieldsTemplateCustomer(
            nameModule,
            codSheet,
            companyCustomer,
          );
          let transformedData: CombinedField[] = templateUserData.fields.map(
            (field) => {
              const combinedData = [...field.data, ...field.config];
              return {
                cod_id: field.cod_id,
                combinedData,
                roles: field.roles,
              };
            },
          );

          setUpdatedFieldsData([]);
          setUpdatedRolesData([]);

          let sheertCompanyData: HeaderSheet[] =
            templateUserData.sheertCompanyData;
          sheertCompanyData.forEach((sheet) => {
            setFinishSheet(sheet.finalized);
          });

          const uniqueHeaders: Set<string> = new Set();
          transformedData.forEach((field) => {
            field.combinedData.forEach((data) => {
              uniqueHeaders.add(data.name);
            });
          });

          setHeaders(Array.from(uniqueHeaders));
          setTransformedData(transformedData);
        } else {
          /* LMS LOGIC SUPERADMIN */
          let templateUserData: RootObject = await getFieldsTemplateCustomer(
            nameModule,
            codSheet,
            companyCustomer,
          );

          templateUserData.sections?.forEach((section) => {
            section.fields?.forEach((field) => {
              field.data.forEach((data) => {
                data.name = data.name.trimEnd();
              });
              field.config.forEach((config) => {
                config.name = config.name.trimEnd();
              });
            });
          });

          let transformedData: CombinedField[] =
            templateUserData.sections?.flatMap((section) => {
              return (
                section.fields?.map((field) => {
                  const combinedData = [...field.data, ...field.config];
                  return {
                    cod_id: field.cod_id,
                    combinedData,
                    roles: field.roles,
                  };
                }) || []
              );
            }) || [];

          setUpdatedFieldsData([]);
          setUpdatedRolesData([]);

          let sheertCompanyData: HeaderSheet[] =
            templateUserData.sheertCompanyData;
          sheertCompanyData.forEach((sheet) => {
            setFinishSheet(sheet.finalized);
          });

          const uniqueHeaders: Set<string> = new Set();
          transformedData.forEach((field) => {
            field.combinedData.forEach((data) => {
              uniqueHeaders.add(data.name);
            });
          });

          setHeaders(Array.from(uniqueHeaders));
          setTransformedData(transformedData);
        }
      } else {
        if (nameModule !== 'lms') {
          /* TEMPLATE NOT LMS WITH CUSTOMER AND ADMIN */
          let templateUserData: RootObject = await getInfoSheetById(
            codProject,
            codSheet,
            codModule,
          );
          let transformedData: CombinedField[] = templateUserData.fields.map(
            (field) => {
              // Filtrar los elementos no deseados de 'data'
              const filteredData = field.data.filter(
                (item) =>
                  item.name.trimEnd().toLocaleLowerCase() !==
                    'nombre del campo' &&
                  item.name.trimEnd().toLocaleLowerCase() !== 'tipo de dato' &&
                  item.name.trimEnd().toLocaleLowerCase() !== 'identificador' &&
                  item.name.trimEnd().toLocaleLowerCase() !==
                    'id de flujo de trabajo' &&
                  item.name.trimEnd().toLocaleLowerCase() !== 'tipo de campo' &&
                  item.name.trimEnd().toLocaleLowerCase() !==
                    'nombre de campo' &&
                  item.name.trimEnd().toLocaleLowerCase() !== 'tipo de datos' &&
                  item.name.trimEnd().toLocaleLowerCase() !==
                    'nombre etiqueta del sistema' &&
                  item.name.trimEnd().toLocaleLowerCase() !== 'id. de campo' &&
                  item.name.trimEnd().toLocaleLowerCase() !== 'designador',
              );

              // Combinar los datos filtrados con la configuración
              const combinedData = [...filteredData, ...field.config];

              return {
                cod_id: field.cod_id,
                combinedData,
                roles: field.roles,
              };
            },
          );

          setUpdatedFieldsData([]);
          setUpdatedRolesData([]);

          let sheertCompanyData: HeaderSheet[] =
            templateUserData.sheertCompanyData;
          sheertCompanyData.forEach((sheet) => {
            setFinishSheet(sheet.finalized);
          });

          const uniqueHeaders: Set<string> = new Set();
          transformedData.forEach((field) => {
            field.combinedData.forEach((data) => {
              uniqueHeaders.add(data.name);
            });
          });

          setHeaders(Array.from(uniqueHeaders));
          setTransformedData(transformedData);
        } else {
          /* TEMPLATE LMS WITH CUSTOMER AND ADMIN */
          let templateUserData: RootObject = await getInfoSheetById(
            codProject,
            codSheet,
            codModule,
          );

          templateUserData.sections?.forEach((section) => {
            section.fields?.forEach((field) => {
              field.data.forEach((data) => {
                data.name = data.name.trimEnd();
              });
              field.config.forEach((config) => {
                config.name = config.name.trimEnd();
              });
            });
          });

          let transformedData: CombinedField[] =
            templateUserData.sections?.flatMap((section) => {
              return (
                section.fields?.map((field) => {
                  const combinedData = [...field.data, ...field.config];
                  return {
                    cod_id: field.cod_id,
                    combinedData,
                    roles: field.roles,
                  };
                }) || []
              );
            }) || [];

          setUpdatedFieldsData([]);
          setUpdatedRolesData([]);

          let sheertCompanyData: HeaderSheet[] =
            templateUserData.sheertCompanyData;
          sheertCompanyData.forEach((sheet) => {
            setFinishSheet(sheet.finalized);
          });

          const uniqueHeaders: Set<string> = new Set();
          transformedData.forEach((field) => {
            field.combinedData.forEach((data) => {
              uniqueHeaders.add(data.name);
            });
          });

          setHeaders(Array.from(uniqueHeaders));
          setTransformedData(transformedData);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (isFinishedSection && rolUser === 'customer') {
        toast({
          title: 'Advertencia',
          description:
            'No se puede modificar porque la sección está finalizada.',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }
  };

  const handleExpandClick = (cod_id: number) => {
    setOpenRow((prev) => (prev === cod_id ? null : cod_id));
  };

  const dataFieldsUpdated = (
    name: string,
    value: string,
    cod_config: number,
  ) => {
    let updateFields = { name, value, cod_config };

    setUpdatedFieldsData((prevUpdatedFields) => {
      const existingFieldIndex = prevUpdatedFields.findIndex(
        (field) => field.name === name && field.cod_config === cod_config,
      );

      if (existingFieldIndex >= 0) {
        const updatedFields = [...prevUpdatedFields];
        updatedFields[existingFieldIndex] = updateFields;
        return updatedFields;
      } else {
        return [...prevUpdatedFields, updateFields];
      }
    });
  };

  const handleRoleChange = (
    cod_id: number,
    roleName: string,
    cod_permission: number,
    value: string,
  ) => {
    let updateRoles = { roleName, cod_permission, value };
    setUpdatedRolesData((prevUpdatedRoles) => {
      const existingFieldIndex = prevUpdatedRoles.findIndex(
        (roles) =>
          roles.roleName === roleName &&
          roles.cod_permission === cod_permission,
      );

      if (existingFieldIndex >= 0) {
        const updatedRol = [...prevUpdatedRoles];
        updatedRol[existingFieldIndex] = updateRoles;
        return updatedRol;
      } else {
        return [...prevUpdatedRoles, updateRoles];
      }
    });

    setTransformedData((prevData) =>
      prevData.map((field) =>
        field.cod_id === cod_id
          ? {
              ...field,
              roles: field.roles.map((role) =>
                role.cod_permission === cod_permission
                  ? { ...role, value }
                  : role,
              ),
            }
          : field,
      ),
    );
  };

  useEffect(() => {
    setFieldsUpdatedToTable(updatedFieldsData);
  }, [updatedFieldsData, setFieldsUpdatedToTable]);

  useEffect(() => {
    setRolesUpdatedToTable(updatedRolesData);
  }, [updatedRolesData, setRolesUpdatedToTable]);

  const hasRoles = transformedData.some((data) => data.roles.length > 0);

  useEffect(() => {
    infoSheetById(codSheet, nameModule);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codSheet, nameModule]);

  return (
    <>
      <Flex
        style={{
          opacity: isFinishedSection && rolUser === 'customer' ? 0.5 : 1,
          pointerEvents:
            isFinishedSection && rolUser === 'customer' ? 'none' : 'auto',
          padding: 0,
          display: 'flex',
          width: '100px',
          minWidth: '-webkit-fill-available',
          flexWrap: 'nowrap',
          overflowY: 'auto',
          height: 'fit-content',
          maxHeight: isFullscreenModule ? '80%' : '50vh',
          marginBottom: '8rem',
        }}
      >
        <Table
          style={{
            minWidth: 'max-content',
            maxWidth: isFullscreenModule ? '100%' : '1200px',
            overflowX: 'auto',
            border: '2px solid #b5b2b2',
          }}
          colorScheme="secondaryGray"
        >
          <Thead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 200,
            }}
          >
            <Tr style={{ border: '2px solid #b5b2b2' }}>
              {headers.map((header) => (
                <Td
                  key={header}
                  align="left"
                  style={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#2501BA',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    color: 'white',
                    zIndex: 1,
                    border: '2px solid #b5b2b2',
                  }}
                >
                  {header}
                </Td>
              ))}

              {hasRoles && (
                <Td
                  align="left"
                  sx={{
                    backgroundColor: '#2501BA',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    color: 'white',
                    border: '2px solid #b5b2b2',
                  }}
                >
                  Roles
                </Td>
              )}
            </Tr>
          </Thead>
          <Tbody style={{ overflowX: 'scroll' }}>
            {transformedData.map((field) => (
              <Row
                key={field.cod_id}
                row={field}
                openRow={openRow}
                headers={headers}
                handleExpandClick={handleExpandClick}
                handleRoleChange={handleRoleChange}
                handleFieldsUpdated={dataFieldsUpdated}
                nameModule={nameModule}
                rolUser={rolUser}
                cardRef={cardRef}
                codModule={codModule}
                codProject={codProject}
              />
            ))}
          </Tbody>
        </Table>
      </Flex>
    </>
  );
};
