// Chakra imports
import { Badge, Flex } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';

export default function Banner(props: {
  illustration?: string | JSX.Element;
  focused?: boolean;
  title?: string;
  description?: string;
  value?: string;
  detail?: string;
  module?: string
  [x: string]: any;
}) {
  const { title, description, module } = props;

  return (
    <Card
      bgGradient="linear(to-b, brand.400, brand.600)"
      backgroundRepeat="no-repeat"
      bgSize="cover"
      bgPosition="10%"
      p={{ base: '20px', md: '20px' }}
      pt={{ base: '40px', md: '35px' }}
    >
      <Flex>
        <Badge
          w="max-content"
          mb="10px"
          fontSize="sm"
          bg="rgba(255,255,255,0.12)"
          color="white"
          fontWeight="bold"
          whiteSpace="normal"
          wordBreak="break-word"
        >
          PROYECTO: {title}
        </Badge>
      </Flex>
      <Flex>
        <Badge
          w="max-content"
          mb="10px"
          fontSize="sm"
          bg="rgba(255,255,255,0.12)"
          color="white"
          fontWeight="bold"
          whiteSpace="normal"
          wordBreak="break-word"
        >
          DESCRIPCIÓN: {description}
        </Badge>
      </Flex>
      <Flex>
        <Badge
          w="max-content"
          mb="10px"
          fontSize="sm"
          bg="rgba(255,255,255,0.12)"
          color="white"
          fontWeight="bold"
          whiteSpace="normal"
          wordBreak="break-word"
        >
          MÓDULO: {module} 
        </Badge>
      </Flex>
    </Card>
  );
}
