import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Text,
  Flex,
  useColorModeValue,
  SimpleGrid,
  Icon,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import { CircProgressMini } from 'components/charts/CircularProgress';
import { CgSearchLoading } from 'react-icons/cg';
// Assets
import { MdCheck } from 'react-icons/md';
import { HeaderSheet } from 'types/Template';

export default function CourseInfo(props: {
  cod_project: number;
  cod_module: number;
  name_module: string;
  headersModule?: HeaderSheet[];
  titleModule: string;
  [x: string]: any;
}) {
  const { headersModule, titleModule } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const completedCount = headersModule.filter(
    (sheet) => sheet.finalized,
  ).length;

  const textColorTertiary = useColorModeValue('secondaryGray.600', 'white');
  return (
    <Card maxH="70vh" p="20px" overflowY="auto">
      <Text color={textColor} fontSize="xl" fontWeight="700" mb="16px">
        {titleModule}
      </Text>
      <Flex alignItems="center" mb="20px">
        <Text color={textColorTertiary} fontSize="md" fontWeight="700">
          {completedCount}/{headersModule.length} COMPLETADOS
        </Text>
        <Icon ms="auto" as={MdCheck} color="green.500" h="18px" w="18px" />
      </Flex>
      <SimpleGrid
        columns={headersModule.length}
        gap="5px"
        alignItems="center"
        mb="20px"
      >
        {headersModule.map((sheet, index) => (
          <Flex
            key={sheet.cod_sheert}
            bg={sheet.finalized ? 'green.500' : 'gray.300'}
            h="8px"
            borderRadius="36px"
          />
        ))}
      </SimpleGrid>
      <Accordion
        defaultIndex={[0]}
        allowToggle
        w={{ sm: '100%', md: '100%', xl: '94%' }}
        mb="16px"
      >
        {headersModule.map((sheet) => (
          <AccordionItem key={sheet.cod_sheert} border="none">
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  p="20px 0px 20px 0px"
                  borderBottom="1px solid"
                >
                  <Box w="30px" me="12px">
                    <CircProgressMini
                      step={sheet.cod_sheert.toString()}
                      percentage={sheet.finalized ? 100 : 0}
                    />
                  </Box>

                  <Box flex="1" textAlign="left">
                    <Text
                      color={sheet.finalized ? textColorTertiary : textColor}
                      fontWeight="500"
                      fontSize="md"
                      me="5px"
                    >
                      {sheet.title}
                    </Text>
                    {sheet.finalized ? (
                      <Icon
                        as={MdCheck}
                        h="18px"
                        w="18px"
                        color={textColorTertiary}
                      />
                    ) : (
                      <Icon as={CgSearchLoading} h="18px" w="18px" color={textColor} />
                    )}
                  </Box>
                  <Icon
                    as={isExpanded ? ArrowUpIcon : ArrowDownIcon}
                    ms="auto"
                    h="22px"
                    w="22px"
                    color={textColorTertiary}
                  />
                </AccordionButton>
                <AccordionPanel p="18px 0px 10px 0px">
                  <Text
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    textAlign="left"
                    alignSelf="flex-start"
                    justifySelf="flex-start"
                  >
                    {sheet.description}
                  </Text>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Card>
  );
}
