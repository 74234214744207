import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import Upload from 'components/dropzone/Upload';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { updateDataUser } from 'services/User';
import useAuth from 'hooks/useAuth';

const schema = yup.object().shape({
  name: yup.string().required('El nombre es requerido'),
  email: yup
    .string()
    .required('El correo es requerido')
    .email('Correo inválido'),
});

export default function Info(props: {
  name: string;
  email: string;
}) {
  const { name, email } = props;
  const [userData, setUserData] = useState({
    name: name,
    email: email,
  });
  const { refreshToken } = useAuth(); 
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const [selectedProfile, setSelectedProfile] = useState<File | null>(null);
  const [isModified, setIsModified] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
    trigger,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  useEffect(() => {
    const hasChanged =
      Object.keys(dirtyFields).length > 0 || selectedProfile !== null;
    setIsModified(hasChanged);
  }, [dirtyFields, selectedProfile]);

  const handleImageProfileChange = (file: File | null) => {
    if (file) {
      if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
        alert(
          'Por favor, selecciona un archivo de imagen válido (JPG, JPEG, PNG)',
        );
        return;
      }
      setSelectedProfile(file);
      setIsModified(true);
      trigger(); // Trigger validation
    }
  };

  const onSubmit = async (values: any) => {
    try {
      if (
        values.name !== userData.name ||
        values.email !== userData.email ||
        selectedProfile
      ) {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('email', values.email);
        if (selectedProfile) {
          formData.append('profile_image', selectedProfile);
        }

        await updateDataUser(formData);

        setUserData({
          name: values.name,
          email: values.email,
        });
        await refreshToken(); 
        setIsEditing(false);
      } else {
        console.log('No se realizaron cambios para actualizar.');
      }
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
    if (!isEditing) {
      reset(userData);
    }
  };

  return (
    <Card>
      <Flex direction="column" mb="40px" ms="10px">
        <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
          Configuraciones de la cuenta
        </Text>
        <Text fontSize="md" color={textColorSecondary}>
          Aquí puede cambiar la información de la cuenta de usuario
        </Text>
      </Flex>

      <SimpleGrid
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        <FormControl isInvalid={!!errors.name} isDisabled={!isEditing}>
          <FormLabel
            display="flex"
            ms="10px"
            fontSize="sm"
            color={textColorPrimary}
            fontWeight="bold"
            _hover={{ cursor: 'pointer' }}
          >
            Nombre
          </FormLabel>
          <Input
            mb="25px"
            id="name"
            {...register('name')}
            variant='main'
            _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
            defaultValue={userData.name} // Asegúrate de establecer el valor predeterminado
            onBlur={() => trigger('name')}
          />
          <Text color="red.500">{errors.name?.message}</Text>
        </FormControl>

        <FormControl isInvalid={!!errors.email} isDisabled={!isEditing}>
          <FormLabel
            display="flex"
            ms="10px"
            fontSize="sm"
            color={textColorPrimary}
            fontWeight="bold"
            _hover={{ cursor: 'pointer' }}
          >
            Correo electrónico
          </FormLabel>
          <Input
            mb="25px"
            id="email"
            variant='main'
            {...register('email')}
            _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
            defaultValue={userData.email} // Asegúrate de establecer el valor predeterminado
            onBlur={() => trigger('email')}
          />
          <Text color="red.500">{errors.email?.message}</Text>
        </FormControl>
      </SimpleGrid>

      <FormControl mt="10px" isDisabled={!isEditing}>
        <FormLabel htmlFor="profile_image">Imagen del perfil</FormLabel>
        <Upload
          pe="20px"
          pb={{ base: '100px', lg: '20px' }}
          onImageSelect={handleImageProfileChange}
        />
      </FormControl>
      <Flex justifyContent="flex-end" gap={4} mt={10}>
        <Button
          minW="183px"
          fontSize="sm"
          fontWeight="500"
          color="white"
          bg="lightbranding.400"
          isDisabled={isSubmitting}
          onClick={handleEditToggle}
        >
          {isEditing ? 'Cancelar' : 'Modificar'}
        </Button>

        {isEditing && (
          <Button
            variant="brand"
            minW="183px"
            fontSize="sm"
            fontWeight="500"
            isDisabled={!isModified || !!errors.name || !!errors.email}
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            Guardar cambios
          </Button>
        )}
      </Flex>
    </Card>
  );
}
