import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  SimpleGrid,
  Grid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  useDisclosure,
  Tbody,
  Table,
  ModalBody,
  ModalFooter,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalHeader,
  Td,
  Tr,
  Image,
} from '@chakra-ui/react';
import MiniCalendar from 'components/calendar/MiniCalendar';
import Hours from './components/Hours';
// Assets
import { VSeparator } from 'components/separator/Separator';
import { ProjectCompany } from 'types/Project';
import { getProjectForRoleWithCompany } from 'services/Projects';
import useAuth from 'hooks/useAuth';
import Card from 'components/card/Card';
import Course from 'components/card/Course';
import { ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons';
import { Companies } from 'types/Company';
import { getCompaniesByCustomers } from 'services/Company';
import ProjectNone from 'assets/img/project/project-not-found.png';
import { useNavigate } from 'react-router-dom';
import patterHR from 'assets/img/landing/pattern.svg';


export default function ListProjects() {
  const [tabState, setTabState] = useState('creates');
  const [projects, setProjects] = useState<ProjectCompany[]>([]);
  const [companyCustomer, setCompanyCustomer] = useState<Companies[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Companies>();

  const { user } = useAuth();
  const navigate = useNavigate();

  const {
    isOpen: isOpenCustomer,
    onOpen: onOpenCustomer,
    onClose: onCloseCustomer,
  } = useDisclosure();

  useEffect(() => {
    if (user?.role_name === 'superadmin') {
      getAllCompanies();
      if (selectedCompany !== null) {
        getAllProjectWithRole();
      }
    } else {
      getAllProjectWithRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  const getAllCompanies = async () => {
    try {
      const { data } = await getCompaniesByCustomers();
      setCompanyCustomer(data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const handleSelectCompany = (company: Companies) => {
    setSelectedCompany(company);
  };

  const getAllProjectWithRole = async () => {
    try {
      if (user?.role_name === 'superadmin') {
        if (selectedCompany) {
          const { data } = await getProjectForRoleWithCompany(
            selectedCompany.name,
          );
          setProjects(data);
        } else {
          return false;
        }
      } else {
        const { data } = await getProjectForRoleWithCompany();
        setProjects(data);
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textTd = useColorModeValue('#F2EFFF', '#7551FF');

  const filterProjects = (status: string) => {
    const now = new Date();
    return projects.filter((project) => {
      const startDate = new Date(project.start_date);
      const endDate = new Date(project.end_date);

      if (status === 'creates') return true;
      if (status === 'progress') return startDate <= now && endDate >= now;
      if (status === 'finalized') return endDate < now;
      return false;
    });
  };

  const handleConfirmSelection = () => {
    if (selectedCompany) {
      onCloseCustomer();
    } else {
      console.log('No company selected');
    }
  };

  const navigateCreateProject = () => {
    navigate('/pages/create-project');
  };

  const generateCourses = (status: string) => {
    const filteredProjects = filterProjects(status);
    // Si no hay proyectos, mostramos una imagen o mensaje
    if (filteredProjects.length === 0) {
      return (
        <Flex justify="center" align="center" direction="column" mt={10}>
          <Text fontSize="lg" color="gray.500" mb={4}>
            No hay proyectos disponibles
          </Text>
          {(user?.role_name === 'superadmin' ||
            user?.role_name === 'admin') && (
            <Flex direction="row" mt="15px">
              <Button
                variant="darkBrand"
                fontSize="sm"
                fontWeight="500"
                borderRadius="70px"
                px="24px"
                py="5px"
                onClick={() => navigateCreateProject()}
              >
                Crear proyecto
              </Button>
            </Flex>
          )}

          <Image src={ProjectNone} alt="No projects available" width="200" />
        </Flex>
      );
    }

    // Si hay proyectos, renderizamos los cursos
    return filteredProjects.map(
      ({
        cod_project,
        start_date,
        end_date,
        created_at,
        name,
        description,
        project_image_url,
        name_company,
      }) => (
        <SimpleGrid columns={1} gap="20px" mb={5} key={cod_project}>
          <Course
            bgBox={project_image_url}
            codProject={cod_project}
            title={name}
            desc={description}
            day="Fecha creación:"
            date={created_at}
            start_date={start_date}
            end_date={end_date}
            name_company={name_company}
            time="1h 30 min"
            hover={`linear-gradient(115.07deg, #b4aaf92b -9.41%, #b4aaf92b 28.04%, #b4aaf92b 71.85%, #b4aaf92b 112.49%, #b4aaf92b 112.49%)`}
          />
        </SimpleGrid>
      ),
    );
  };

  return (
    <>
      <Grid
        pt={{ base: '130px', md: '80px', xl: '80px' }}
        gridTemplateColumns={{ md: '2.15fr 1fr', xl: '2.95fr 1fr' }}
        display={{ base: 'block', lg: 'grid' }}
      >
        <Flex gridArea="1 / 1 / 2 / 2" display={{ base: 'block', lg: 'flex' }}>
          <Tabs variant="soft-rounded" colorScheme="brandTabs">
            <TabList
              mx={{ base: '10px', lg: '30px' }}
              overflowX={{ sm: 'scroll', lg: 'unset' }}
            >
              <Flex>
                <Tab
                  pb="0px"
                  flexDirection="column"
                  onClick={() => setTabState('creates')}
                  me="10px"
                  bg="unset"
                  _selected={{
                    bg: 'none',
                  }}
                  _focus={{ border: 'none' }}
                  minW="max-content"
                >
                  <Flex align="center">
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="500"
                      me="12px"
                    >
                      Creados
                    </Text>
                    <Text
                      color="secondaryGray.600"
                      fontSize="md"
                      fontWeight="400"
                    >
                      {filterProjects('creates').length}
                    </Text>
                  </Flex>
                  <Box
                    height="4px"
                    w="100%"
                    transition="0.1s linear"
                    bg={tabState === 'creates' ? 'brand.500' : 'transparent'}
                    mt="15px"
                    borderRadius="30px"
                  />
                </Tab>
                <Tab
                  onClick={() => setTabState('progress')}
                  pb="0px"
                  me="10px"
                  bg="unset"
                  _selected={{
                    bg: 'none',
                  }}
                  _focus={{ border: 'none' }}
                  minW="max-content"
                  flexDirection="column"
                >
                  <Flex align="center">
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="500"
                      me="12px"
                    >
                      En progreso
                    </Text>
                    <Text
                      color="secondaryGray.600"
                      fontSize="md"
                      fontWeight="400"
                    >
                      {filterProjects('progress').length}
                    </Text>
                  </Flex>
                  <Box
                    height="4px"
                    w="100%"
                    transition="0.1s linear"
                    bg={tabState === 'progress' ? 'brand.500' : 'transparent'}
                    mt="15px"
                    borderRadius="30px"
                  />
                </Tab>
                <Tab
                  pb="0px"
                  flexDirection="column"
                  onClick={() => setTabState('finalized')}
                  bg="unset"
                  _selected={{
                    bg: 'none',
                  }}
                  _focus={{ border: 'none' }}
                  minW="max-content"
                >
                  <Flex align="center">
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="500"
                      me="12px"
                    >
                      Finalizados
                    </Text>
                    <Text
                      color="secondaryGray.600"
                      fontSize="md"
                      fontWeight="400"
                    >
                      {filterProjects('finalized').length}
                    </Text>
                  </Flex>
                  <Box
                    height="4px"
                    w="100%"
                    transition="0.1s linear"
                    bg={tabState === 'finalized' ? 'brand.500' : 'transparent'}
                    mt="15px"
                    borderRadius="30px"
                  />
                </Tab>
              </Flex>
            </TabList>
            <TabPanels>
              <TabPanel px="0px">{generateCourses('creates')}</TabPanel>
              <TabPanel px="0px">{generateCourses('progress')}</TabPanel>
              <TabPanel px="0px">{generateCourses('finalized')}</TabPanel>
            </TabPanels>
          </Tabs>
          <VSeparator mx="30px" h="100%" />
        </Flex>
        <Card
          alignItems="center"
          flexDirection="column"
          gridArea="1 / 2 / 2 / 3"
          w="100%"
        >
          {user.role_name === 'superadmin' && (
            <Grid
              mb="20px"
              gridTemplateColumns={{ xl: 'repeat(1, 1fr)', '2xl': '1fr' }}
              gap={{ base: '20px', xl: '20px' }}
              display={{ base: 'block', xl: 'grid' }}
            >
              <Flex flexDirection="column">
                <Flex
                  direction="column"
                  background={`linear-gradient(to bottom, #b2aaf9, #b2aaf9)`}
                  bgSize="cover"
                  position="relative"
                  py={{ base: '30px', md: '56px' }}
                  px={{ base: '30px', md: '64px' }}
                  borderRadius="30px"
                >
                  {user.role_name === 'superadmin' && (
                    <>
                      <Box
                        _before={{
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundImage: `url(${patterHR})`,
                          backgroundSize: '100%',
                          backgroundRepeat: 'repeat',
                          backgroundPosition: 'center',
                          opacity: 0.2,
                          zIndex: 0,
                        }}
                      />
                      <Text
                        fontSize={{ base: '18px', md: '16px' }}
                        color="white"
                        mb="14px"
                        maxW={{
                          base: '100%',
                          md: '100%',
                          lg: '100%',
                          xl: '100%',
                          '2xl': '100%',
                          '3xl': '100%',
                        }}
                        fontWeight="700"
                      >
                        Administre los proyectos por compañia
                      </Text>
                      <Flex align="center">
                        <Button
                          bg="white"
                          color="black"
                          _hover={{ bg: 'whiteAlpha.900' }}
                          _active={{ bg: 'white' }}
                          _focus={{ bg: 'white' }}
                          fontWeight="500"
                          fontSize="14px"
                          py="20px"
                          px="27"
                          me="38px"
                          onClick={onOpenCustomer}
                        >
                          Seleccione una compañia
                        </Button>
                      </Flex>
                    </>
                  )}
                </Flex>
              </Flex>
            </Grid>
          )}
          <MiniCalendar
            gridArea={{ md: '1 / 1 / 2 / 2;', lg: '1 / 1 / 2 / 2' }}
            selectRange={false}
            mb="20px"
          />
          {/* <Schedule gridArea={{ md: '1 / 2 / 2 / 3', lg: '2 / 1 / 3 / 2' }} mb='20px' /> */}
          <Hours gridArea={{ md: '2 / 1 / 3 / 3', lg: '3 / 1 / 4 / 2' }} />
        </Card>
      </Grid>
      <Modal isOpen={isOpenCustomer} onClose={onCloseCustomer} size="lg">
        <ModalOverlay />
        <ModalContent maxHeight="70vh">
          <ModalHeader>Compañias</ModalHeader>
          <ModalBody maxHeight="70vh" height="70vh" overflowY="auto">
            <Table style={{ minWidth: 'max-content', maxWidth: '600px' }}>
              <Tbody>
                {companyCustomer.map((company) => (
                  <Tr
                    key={company.cod_company}
                    onClick={() => handleSelectCompany(company)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor:
                        selectedCompany?.cod_company === company.cod_company
                          ? textTd
                          : 'transparent',
                    }}
                  >
                    <Td
                      style={{
                        border: '2px solid #b5b2b2',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {company.name}
                      {selectedCompany?.cod_company === company.cod_company ? (
                        <Button
                          colorScheme="blue"
                          size="sm"
                          leftIcon={<CheckIcon />}
                        >
                          Seleccionado
                        </Button>
                      ) : (
                        <Button
                          colorScheme="gray"
                          size="sm"
                          leftIcon={<ArrowForwardIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectCompany(company);
                          }}
                        >
                          Seleccionar
                        </Button>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={onCloseCustomer}>
              Cancelar
            </Button>
            <Button colorScheme="blue" ml={3} onClick={handleConfirmSelection}>
              Selecciona
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
