import React, { useEffect, useState } from 'react';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  SortingState,
} from '@tanstack/react-table';
import {
  Box,
  Avatar,
  Text,
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useColorModeValue,
  Button,
  Select,
  useToast,
} from '@chakra-ui/react';
import { Roles, Users } from 'types/Auth';
import Card from 'components/card/Card';
import { getAllRolesUser, updateRoleUser } from 'services/User';
import { deleteProject, getProjectForRoleWithCompany } from 'services/Projects';
import { ProjectCompany } from 'types/Project';
import ProjectCard from './modules/ProjectCard';
import useAuth from 'hooks/useAuth';

const columnHelper = createColumnHelper<Users>();

export default function CompanyInfo(props: {
  banner: string;
  avatar: string;
  name: string;
  job?: string;
  description?: string;
  tableData: Users[];
  [x: string]: any;
}) {
  const { banner, avatar, name, job, description, tableData, ...rest } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rolesData, setRolesData] = useState<Roles[]>([]);
  const [projects, setProjects] = useState<ProjectCompany[]>([]);
  const toast = useToast();
  const { user } = useAuth();

  const [data, setData] = React.useState<Users[]>(tableData);

  useEffect(() => {
    if (name !== null) {
      getAllRoles();
      getAllProjectWithCompany();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const getAllRoles = async () => {
    try {
      const { data } = await getAllRolesUser();
      const filteredRoles = data.filter(
        (role: Roles) => role.role_name.toLowerCase() !== 'superadmin',
      );
      setRolesData(filteredRoles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const getAllProjectWithCompany = async () => {
    try {
      if (user?.role_name === 'superadmin') {
        const { data } = await getProjectForRoleWithCompany(name);
        setProjects(data);
      } else {
        const { data } = await getProjectForRoleWithCompany();
        setProjects(data);
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue(
    'white !important',
    '#111C44 !important',
  );
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const optionColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const optionBg = useColorModeValue('white', 'gray.700');

  const [activeSection, setActiveSection] = useState('usuarios');

  const columns = [
    columnHelper.accessor('email', {
      id: 'email',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Email
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('role_name', {
      id: 'role_name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Rol
        </Text>
      ),
      cell: (info) => (
        <Select
          variant="subtle"
          placeholder="Selecciona un rol"
          defaultValue={info.getValue()} // Rol actual del usuario
          onChange={(event) =>
            handleRoleChange(info.row.original, event.target.value)
          }
          sx={{
            option: {
              color: optionColor,
              backgroundColor: optionBg,
            },
          }}
        >
          {rolesData.map((role) => (
            <option key={role.cod_ruser} value={role.role_name}>
              {role.role_name}
            </option>
          ))}
        </Select>
      ),
    }),
    columnHelper.accessor('cod_user', {
      id: 'cod_user',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Codigo de usuario:
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColorSecondary} fontSize="sm" fontWeight="500">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    {
      id: 'edit',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Actualizar
        </Text>
      ),
      cell: (info: any) => (
        <Button
          size="sm"
          colorScheme="blue"
          onClick={() => handleChangeRole(info.row.original)}
        >
          Guardar
        </Button>
      ),
    },
  ];

  const handleRoleChange = (user: Users, newRole: string) => {
    setData((prevData) =>
      prevData.map((u) =>
        u.cod_user === user.cod_user ? { ...u, role_name: newRole } : u,
      ),
    );
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const handleChangeRole = async (rowData: Users) => {
    if (!rowData.role_name) {
      toast({
        title: 'Advertencia',
        description: 'Elige un rol para el usuario',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      const dataToSend = {
        userId: rowData.cod_user,
        role_name: rowData.role_name,
      };
      await updateRoleUser(dataToSend);
    } catch (error) {
      console.error('Error update roles:', error);
    }
  };

  const handleDeleteProject = async (codProject: number) => {
    await deleteProject(codProject);
    getAllProjectWithCompany();
  };

  return (
    <Card mb={{ base: '0px', lg: '20px' }} alignItems="center" {...rest}>
      <Box
        bg={`url(${banner})`}
        bgSize="cover"
        borderRadius="16px"
        h="131px"
        w="100%"
      />
      <Avatar
        mx="auto"
        src={avatar}
        h="150px"
        w="150px"
        mt="-80px"
        border="4px solid"
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {description}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {job}
      </Text>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
        marginTop={10}
      >
        <Box>
          <Flex justify="space-evenly" align="center" mb="20px">
            <Button
              colorScheme={activeSection === 'usuarios' ? 'navy' : 'gray'}
              onClick={() => setActiveSection('usuarios')}
            >
              Usuarios
            </Button>
            <Button
              colorScheme={activeSection === 'proyectos' ? 'navy' : 'gray'}
              onClick={() => setActiveSection('proyectos')}
            >
              Proyectos
            </Button>
          </Flex>

          {/* Sección de Usuarios */}
          {activeSection === 'usuarios' && (
            <Box height={300} overflowY="auto">
              <Flex
                align={{ sm: 'flex-start', lg: 'center' }}
                justify="space-between"
                w="100%"
                px="10px"
                pb="20px"
                mb="10px"
              >
                <Text color={textColor} fontSize="xl" fontWeight="600">
                  Usuario de la compañia
                </Text>
              </Flex>
              <Table>
                <Thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Th
                          key={header.id}
                          colSpan={header.colSpan}
                          pe="10px"
                          borderColor={borderColor}
                        >
                          <Flex
                            align="center"
                            fontSize={{ sm: '10px', lg: '12px' }}
                            color="gray.400"
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </Flex>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody>
                  {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          )}

          {/* Sección de Proyectos */}
          {activeSection === 'proyectos' && (
            <Flex flexDirection="column" height={300} overflowY="auto">
              <Card p="0px" w="100%" px="5px" py="5px">
                <Flex
                  align={{ sm: 'flex-start', lg: 'center' }}
                  justify="space-evenly"
                  w="100%"
                  px="5px"
                  py="5px"
                >
                  <Text color={textColor} fontSize="xl" fontWeight="600">
                    Proyectos
                  </Text>
                </Flex>
                {projects.map((project) => (
                  <ProjectCard
                    key={project.cod_project}
                    cod_project={project.cod_project}
                    created_at={project.created_at}
                    end_date={project.end_date}
                    start_date={project.start_date}
                    name={project.name}
                    project_image_url={project.project_image_url}
                    description={project.description}
                    onDelete={handleDeleteProject}
                  />
                ))}
              </Card>
            </Flex>
          )}
        </Box>
      </Flex>
    </Card>
  );
}
