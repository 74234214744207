// Chakra imports
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import configModuleImg from 'assets/img/modules/config.png';
import { useNavigate } from 'react-router-dom';

export default function ConfigModule(props: {
  title: string;
  desc: string;
  codProject: number;
  codModule: number;
  nameModule: string;
  displayTitleModule: string;
  userAssigned: boolean;
  nameCompany: string;
  [x: string]: any;
}) {
  const { title, desc, codProject, codModule, nameModule, displayTitleModule, nameCompany, userAssigned } =
    props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const bgCard = useColorModeValue('white', 'navy.700');

  const navigate = useNavigate();

  const handleClick = (
    codProject: number,
    codModule: number,
    title: string,
    desc: string,
    nameModule: string,
    displayTitleModule: string,
    nameCompany: string,
  ) => {
    const params = {
      cod_project: codProject,
      cod_module: codModule,
      title: title,
      desc: desc,
      nameModule: nameModule,
      titleModule: displayTitleModule,
      nameCompany: nameCompany
    };

    navigate('/pages/templates', { state: params });
  };

  return (
    <Flex direction="column" p={{ base: '10px', md: '10px' }}>
      <Card
        backgroundRepeat="no-repeat"
        bg={bgCard}
        p="30px"
        mt="-80px"
        bgImage={`url(${configModuleImg})`}
        bgRepeat="no-repeat"
        bgSize="cover"
        bgPosition="center"
        w="100%"
        borderRadius="20px"
        me="15px"
        position="relative"
        _before={{
          content: '""',
          display: 'block',
          paddingTop: '56.25%',
        }}
        onClick={() =>
          nameModule && !userAssigned &&
          handleClick(
            codProject,
            codModule,
            title,
            desc,
            nameModule,
            displayTitleModule,
            nameCompany
          )
        }
      />

      <Flex mt="30px" direction={{ base: 'column', md: 'row' }}>
        <Box me="auto" mb={{ base: '40px', md: '0px' }}>
          <Text fontSize="lg" fontWeight="700" color={textColor}>
            Ajustes y cambios en módulos
          </Text>
          <Text fontSize="md" fontWeight="400" maxW="292px">
            Gestione los modules, ajustando los parametros.
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}
