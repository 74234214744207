import React, { useEffect, useState } from 'react';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { ModalCloseButton } from 'components/modal/ModalCloseButton';
import { ModulesContent } from './ModalContent';

type Props = {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  codProject?: number;
  titleProject: string;
  descProject?: string;
  imageProject?: string;
  nameCompany?: string;
};

export const ModalModules = ({
  children,
  isOpen,
  onClose,
  codProject,
  titleProject,
  descProject,
  imageProject,
  nameCompany
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(isOpen);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const closeModal = () => {
    setIsModalOpen(false);
    onClose();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeModal}
      id="rsi"
      variant="rsi"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      scrollBehavior="inside"
      size="4xl"
      isCentered
    >
      <ModalOverlay />
      <ModalCloseButton onClose={closeModal} />
      <ModalContent
        borderRadius="lg"
        p={4}
        boxShadow="xl"
        margin="auto"
      >
        <ModulesContent
          codProject={codProject}
          titleProject={titleProject}
          descProject={descProject}
          imageProject={imageProject}
          onClosePrimary={closeModal}
          nameCompany={nameCompany}
        />
        {children}
      </ModalContent>
    </Modal>
  );
};
