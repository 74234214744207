import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack,
  Text,
  useColorModeValue,
  Select,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getAllRolesUser, registerCustomer } from 'services/User';
import { Roles } from 'types/Auth';
import { Companies } from 'types/Company';

interface RegisterUserProps {
  companyCustomer: Companies[];
}

export default function RegisterUser({ companyCustomer }: RegisterUserProps) {
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const inputVariant = useColorModeValue('gray.900', 'white');
  const optionColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const optionBg = useColorModeValue('white', 'gray.700');

  const [rolesData, setRolesData] = useState<Roles[]>([]);

  useEffect(() => {
    getAllRoles();
  }, []);

  const getAllRoles = async () => {
    try {
      const { data } = await getAllRolesUser();
      setRolesData(data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const registerUser = async (data: {
    name: string;
    email: string;
    password: string;
    name_company: string;
    rol: string;
  }) => {
    try {
      await registerCustomer(data);
      /* getAllCompanies();
      allUsersCompany(selectedCompany?.name); */
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Box>
        <Heading as="h5" size="md">
          Registro de usuario
        </Heading>
        <Text fontSize="sm">Ingresa los detalles del usuario.</Text>
      </Box>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          const formData = new FormData(e.target as HTMLFormElement);
          const email = formData.get('email') as string;
          const password = formData.get('password') as string;
          const name_company = formData.get('name_company') as string;
          const rol = formData.get('rol') as string;
          const name = formData.get('name') as string;

          registerUser({ name, email, password, name_company, rol });
        }}
      >
        <Box>
          <VStack spacing={4}>
            <FormControl id="rol" isRequired>
              <FormLabel>Rol</FormLabel>
              <Select
                name="rol"
                placeholder="Seleccione un rol"
                onChange={(e) => setSelectedRole(e.target.value)} // Actualiza el estado al cambiar el rol
                sx={{
                  option: {
                    color: optionColor,
                    backgroundColor: optionBg,
                  },
                }}
              >
                {rolesData.map((rol) => (
                  <option key={rol.cod_ruser} value={rol.role_name}>
                    {rol.role_name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl id="name" isRequired>
              <FormLabel>Nombre</FormLabel>
              <Input
                color={inputVariant}
                name="name"
                type="text"
                placeholder="Ingresa tu nombre"
              />
            </FormControl>

            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                color={inputVariant}
                name="email"
                type="email"
                placeholder="Ingresa tu email"
              />
            </FormControl>

            <FormControl id="password" isRequired>
              <FormLabel>Contraseña</FormLabel>
              <Input
                color={inputVariant}
                name="password"
                type="password"
                placeholder="******"
              />
            </FormControl>

            <FormControl id="name_company" isRequired>
              <FormLabel>Compañía</FormLabel>
              <Select
                name="name_company"
                placeholder="Seleccione una compañía"
                isDisabled={selectedRole === 'superadmin' || selectedRole === 'consultor'} // Deshabilita si el rol es 'superadmin'
                sx={{
                  option: {
                    color: optionColor,
                    backgroundColor: optionBg,
                  },
                }}
              >
                {companyCustomer.map((company) => (
                  <option key={company.name} value={company.name}>
                    {company.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </VStack>
        </Box>

        <Box m={5}>
          <Button colorScheme="brandScheme" type="submit" w="full">
            Registrar
          </Button>
        </Box>
      </form>
    </>
  );
}
