import React, { useState } from 'react';
// Chakra imports
import {
  Flex,
  IconButton,
  Image,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
// Assets
import moment from 'moment-timezone';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

export default function ProjectCard(props: {
  created_at: string;
  end_date: string;
  start_date: string;
  name: string;
  project_image_url: string;
  description: string;
  cod_project: number;
  onDelete: (codProject: number) => void;
}) {
  const {
    created_at,
    end_date,
    start_date,
    name,
    project_image_url,
    description,
    cod_project,
    onDelete,
  } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue('brands.900', 'white');
  const bgItem = useColorModeValue(
    { bg: 'white', boxShadow: '0px 40px 58px -20px rgba(112, 144, 176, 0.12)' },
    { bg: 'navy.700', boxShadow: 'unset' },
  );
  const [codProject, setCodProject] = useState<number>(0);
  const textColorDate = useColorModeValue('secondaryGray.600', 'white');
  const {
    isOpen: isOpenCustomer,
    onOpen: onOpenCustomer,
    onClose: onCloseCustomer,
  } = useDisclosure();

  const handleConfirm = (code: number) => {
    onOpenCustomer();
    setCodProject(code);
  };

  const handleConfirmDelete = async () => {
    try {
      onDelete(codProject);
      onCloseCustomer();
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  const bgModal = useColorModeValue('#ffffff', 'navy.800');

  return (
    <>
      <Card
        _hover={bgItem}
        bg="transparent"
        boxShadow="unset"
        px="24px"
        py="21px"
        transition="0.2s linear"
      >
        <Flex direction={{ base: 'column' }} justify="center">
          <Flex position="relative" align="center">
            <Flex
              direction="column"
              w={{ base: '70%', md: '100%' }}
              me={{ base: '4px', md: '32px', xl: '10px', '3xl': '32px' }}
            >
              <Image
                src={project_image_url}
                w="66px"
                h="66px"
                borderRadius="20px"
                me="16px"
              />
              <Text
                color={textColor}
                fontSize={{
                  base: 'md',
                }}
                mb="5px"
                fontWeight="bold"
                me="14px"
              >
                Proyecto: {name}
              </Text>
              <Text
                color="secondaryGray.600"
                fontSize={{
                  base: 'sm',
                }}
                fontWeight="400"
                me="14px"
              >
                Descripción: {description}
              </Text>
            </Flex>
            <Flex
              direction="column"
              w={{ base: '70%', md: '100%' }}
              me={{ base: '4px', md: '32px', xl: '10px', '3xl': '32px' }}
            >
              <Text
                ms="auto"
                fontWeight="700"
                fontSize="sm"
                color={textColorDate}
              >
                Fecha creación:{' '}
                {moment.tz(created_at, 'America/Bogota').format('D/M/YYYY')}
              </Text>
              <Text
                ms="auto"
                fontWeight="700"
                fontSize="sm"
                color={textColorDate}
              >
                Fecha inicio:{' '}
                {moment.tz(start_date, 'America/Bogota').format('D/M/YYYY')}
              </Text>
              <Text
                ms="auto"
                fontWeight="700"
                fontSize="sm"
                color={textColorDate}
              >
                Fecha fin:{' '}
                {moment.tz(end_date, 'America/Bogota').format('D/M/YYYY')}
              </Text>
            </Flex>
            <Flex>
              <IconButton
                icon={<EditIcon />}
                colorScheme="blue"
                aria-label="Editar"
                mr="2"
              />
              <IconButton
                icon={<DeleteIcon />}
                colorScheme="red"
                aria-label="Eliminar"
                onClick={() => handleConfirm(cod_project)}
              />
            </Flex>
          </Flex>
        </Flex>
      </Card>
      <Modal isOpen={isOpenCustomer} onClose={onCloseCustomer} size="lg">
        <ModalOverlay />
        <ModalContent bg={bgModal}>
          <ModalHeader>Confirmar</ModalHeader>
          <ModalBody overflowY="auto">
            ¿Está seguro de eliminar este proyecto?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={onCloseCustomer}>
              Cancelar
            </Button>
            <Button colorScheme="blue" ml={3} onClick={handleConfirmDelete}>
              Eliminar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
