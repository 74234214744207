// Chakra imports
import {
  Avatar,
  Flex,
  Box,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import patterHR from 'assets/img/landing/pattern.svg';

export default function Settings(props: {
  name: string;
  avatar: string;
  role: string;
}) {
  // eslint-disable-next-line
  const { name, avatar, role } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  return (
    <Card mb="20px" alignItems="center">
      {/* <Image src={banner} borderRadius="16px" /> */}
      <Flex
        w="100%"
        background={`linear-gradient(to bottom, #b2aaf9, #b2aaf9)`}
        minH="127px"
        position="relative"
        borderRadius="16px"
      >
        <Box
          _before={{
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${patterHR})`,
            backgroundSize: '100%',
            backgroundRepeat: 'repeat',
            backgroundPosition: 'center',
            opacity: 0.2,
            zIndex: 0,
          }}
        />
      </Flex>
      <Avatar mx="auto" src={avatar} h="87px" w="87px" mt="-43px" mb="15px" />
      <Text fontSize="2xl" textColor={textColorPrimary} fontWeight="700">
        {name}
      </Text>
      <Flex align="center" mx="auto" px="15px">
        <Text
          me="4px"
          color={textColorSecondary}
          fontSize="sm"
          fontWeight="400"
          lineHeight="100%"
        >
          Tipo de cuenta:
        </Text>
        <Text> {role} </Text>
      </Flex>
    </Card>
  );
}
