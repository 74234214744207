// Chakra imports
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';

// Custom components
import Info from './components/Info';
import Password from './components/Password';
import Profile from './components/Profile';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { User } from 'types/Auth';

export default function SettingsOverview() {
  let { user, imageTimestamp } = useAuth();
  const [userInfo, setUserInfo] = useState<User>();

  useEffect(() => {
    setUserInfo(user);
  }, [user]);

  return userInfo ? (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, lg: 2 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        {/* Column Left */}
        <Flex direction="column">
          <Profile
            name={userInfo.name || 'Nombre no disponible'}
            avatar={`${userInfo?.img_user}?t=${imageTimestamp}` || 'default-avatar-url.jpg'}
            role={userInfo.role_name || 'Rol no disponible'}
          />
          <Password />
        </Flex>
        {/* Column Right */}
        <Flex direction="column">
          <Info
            name={userInfo.name || 'Nombre no disponible'}
            email={userInfo.email || 'Correo no disponible'}
          />
        </Flex>
      </SimpleGrid>
    </Box>
  ) : (
    <Text>Cargando información del usuario...</Text> // Fallback de carga
  );
}
