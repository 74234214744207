import React from 'react';
import { useRoutes, RouteObject, Navigate } from 'react-router-dom';
import { Icon } from '@chakra-ui/react';
import {
  MdPerson,
  MdHome,
  MdOutlineBusiness,
  MdCreateNewFolder,
  MdDeveloperBoard,
} from 'react-icons/md';
import { BsListTask } from 'react-icons/bs';
import { RiListSettingsFill } from 'react-icons/ri';

import MainDashboard from 'views/customer/default';
import AdminPanel from 'views/admin';
import useAuth from 'hooks/useAuth';
import ErrorComponent from 'views/error';
import NewProject from 'views/projects/newProduct';
import ListProjects from 'views/projects/listProjects';

import Modules from 'views/modules';
import { CgTemplate } from 'react-icons/cg';
import SettingsOverview from 'views/customer/profile';
import { FaCog } from 'react-icons/fa';
import PreconfigurationDetailModule from 'views/modules/components/modules/preconfiguration';
import RevisionDetailModule from 'views/modules/components/modules/revision';
import ConfigDetailModule from 'views/modules/components/modules/configuration';

const dashboardRoutes: RoutesType[] = [
  {
    name: 'Panel principal',
    layout: '/pages',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
    roles: [''],
  },
  {
    name: 'Proyectos',
    layout: '/pages/',
    path: '/main',
    icon: (
      <Icon
        as={RiListSettingsFill}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    roles: ['customer', 'admin', 'consultor', 'superadmin'],
    collapse: true,
    items: [
      {
        name: 'Lista de proyectos',
        layout: '/pages',
        path: '/list-projects',
        component: <ListProjects />,
        secondary: true,
        icon: (
          <Icon as={BsListTask} width="20px" height="20px" color="inherit" />
        ),
        roles: ['customer', 'admin', 'superadmin', 'consultor'],
      },
      {
        name: 'Crear proyecto',
        layout: '/pages',
        path: '/create-project',
        component: <NewProject />,
        icon: (
          <Icon
            as={MdCreateNewFolder}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        secondary: true,
        roles: ['admin', 'superadmin'],
      },
      {
        name: 'Módulos',
        layout: '/pages',
        path: '/modules',
        component: <Modules />,
        secondary: true,
        icon: (
          <Icon as={MdDeveloperBoard} width="20px" height="20px" color="inherit" />
        ),
        roles: ['customer', 'admin', 'superadmin', 'consultor'],
      },
      {
        name: 'Preconfiguración',
        layout: '/pages',
        path: '/preconfiguration',
        component: <PreconfigurationDetailModule />,
        secondary: true,
        icon: (
          <Icon as={FaCog} width="20px" height="20px" color="inherit" />
        ),
        roles: ['customer', 'admin', 'superadmin', 'consultor'],
      },
      {
        name: 'Plantillas',
        layout: '/pages',
        path: '/templates',
        component: <ConfigDetailModule />,
        secondary: true,
        icon: (
          <Icon as={CgTemplate} width="20px" height="20px" color="inherit" />
        ),
        roles: ['customer', 'admin', 'superadmin', 'consultor'],
      },
      {
        name: 'Revisión',
        layout: '/pages',
        path: '/revision',
        component: <RevisionDetailModule />,
        secondary: true,
        icon: (
          <Icon as={FaCog} width="20px" height="20px" color="inherit" />
        ),
        roles: ['customer', 'admin', 'superadmin', 'consultor'],
      },
    ],
  },
  {
    name: 'Administrador',
    layout: '/pages',
    path: '/admin/panel',
    icon: (
      <Icon as={MdOutlineBusiness} width="20px" height="20px" color="inherit" />
    ),
    component: <AdminPanel />,
    roles: ['superadmin', 'admin'],
  },
  {
    name: 'Perfil',
    layout: '/pages',
    path: '/profile',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <SettingsOverview />,
    roles: ['customer', 'admin', 'superadmin', 'consultor'],
  }
];

const DashboardRoutes: React.FC = () => {
  const { role } = useAuth();

  const routeObjects: RouteObject[] = dashboardRoutes.map((route) => {
    if (route.roles && !route.roles.includes(role || '')) {
      return {
        path: `${route.layout}${route.path}`,
        element: <Navigate to="/pages/error" replace />,
      };
    }
    return {
      path: `${route.layout}${route.path}`,
      element: route.component,
    };
  });

  const routeElements: RouteObject[] = [
    ...routeObjects,
    {
      path: '*',
      element: <ErrorComponent />,
    },
  ];

  const element = useRoutes(routeElements);
  return <>{element}</>;
};

export { dashboardRoutes };
export default DashboardRoutes;
