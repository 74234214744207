// Chakra imports
import {
  Box,
  Flex,
  Grid,
  SimpleGrid,
  Text,
  IconButton,
  Icon,
  useColorModeValue,
  Select,
  useToast,
  Button,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import Earn from './components/Earn';
import PrevConfigModule from './components/PrevConfig';
import ConfigModule from './components/ConfigModule';
import Banner from './components/Banner';
import { useLocation, useNavigate } from 'react-router-dom';
import RevisionModule from './components/RevisionModule';
import { useEffect, useState } from 'react';
import { TemplateData } from 'types/Template';
import { getAllTemplates } from 'services/Template';
import {
  getModulesTheProjects,
  listUsersAvaiblablesModule,
} from 'services/Modules';
import { ModuleProjects } from 'types/Module';
import { MdArrowBack, MdOutlineDoneOutline } from 'react-icons/md';
import useAuth from 'hooks/useAuth';

interface SelectedModule {
  cod_module: number;
  displayTitle: string;
  nameMod: string;
}

export default function Modules() {
  const location = useLocation();
  const { title, desc, cod_project, nameCompany } = location.state || {};
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [templates, setTemplates] = useState<TemplateData[]>([]);
  const [modulesData, setModulesProjects] = useState<ModuleProjects[]>([]);
  const [selectedModule, setSelectedModule] = useState<
    SelectedModule | undefined
  >();
  const [moduleActive, setModuleActive] = useState<number | undefined>(0);
  const [nameModule, setNameModule] = useState<string | undefined>('');
  const [displayTitleModule, setDisplayTitleModule] = useState<
    string | undefined
  >('');
  const [userAssigned, setUserAssigned] = useState<boolean>(false);
  const { user } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    getAllModules();
    getModulesActives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (moduleActive !== 0) {
      isModuleSelect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleActive]);

  const isModuleSelect = async () => {
    try {
      const users = await listUsersAvaiblablesModule(
        nameCompany,
        cod_project,
        moduleActive,
      );
      const isAssigned = checkUserAssignment(users);
      setUserAssigned(isAssigned);
      if (isAssigned) {
        toast({
          title: 'Advertencia',
          description: 'No ha sido asignado a este módulo.',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const checkUserAssignment = (users: { email: string }[]): boolean => {
    const assignedUser = users.some((u) => u.email === user.email);
    return assignedUser;
  };

  const getAllModules = async () => {
    try {
      let { data } = await getAllTemplates();
      setTemplates(data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const getModulesActives = async () => {
    try {
      const modulesProjectsData = await getModulesTheProjects(cod_project);
      setModulesProjects(modulesProjectsData);
    } catch (error) {
      console.log('error fetch', error);
    }
  };

  const activeModulesWithTitles = modulesData.map((activeModule) => {
    const template = templates.find(
      (template) => template.cod_template === activeModule.cod_module,
    );
    const title = template
      ? template.title.split('-')[0].trim()
      : 'Unknown Module';
    const dataName = template ? template.name_module : '';

    return {
      cod_module: activeModule.cod_module,
      displayTitle: `${title} (${dataName})`,
      nameMod: dataName,
    };
  });

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = JSON.parse(event.target.value);
    setSelectedModule({
      cod_module: selectedValue.cod_module,
      displayTitle: selectedValue.displayTitle,
      nameMod: selectedValue.nameMod,
    });
    setNameModule(selectedValue.nameMod);
    setModuleActive(selectedValue.cod_module);
    setDisplayTitleModule(selectedValue.displayTitle);
  };

  const handleClick = (
    title: string,
    desc: string,
    cod_project: number,
    nameCompany: string
  ) => {
    const params = {
      title: title,
      desc: desc,
      cod_project: cod_project,
      nameCompany: nameCompany,
    };

    navigate('/pages/list-projects', { state: params });
  };

  return (
    <>
      <Button
        mt={{ base: '130px', md: '80px', xl: '80px' }}
        onClick={() => handleClick(title, desc, cod_project, nameCompany)}
        width={100}
        colorScheme="brand"
        mb={5}
        leftIcon={<MdArrowBack />}
      >
        Atrás
      </Button>
      <Box>
        <Grid
          w="100%"
          mb="20px"
          gap="20px"
          templateColumns={{
            base: '1fr',
            lg: '2.1fr 1fr',
            '2xl': '2.1fr 1fr',
          }}
        >
          <Banner
            title={title}
            description={desc}
            module={
              selectedModule
                ? selectedModule.displayTitle
                : 'No module selected'
            }
            me="20px"
            gridArea={{
              base: '1 / 1 / 2 / 2',
              lg: '1 / 1 / 2 / 2',
              '2xl': '1 / 1 / 2 / 2',
            }}
          />

          <Grid
            gridArea={{
              base: '2 / 1 / 3 / 2',
              lg: '1 / 2 / 3 / 3',
              '2xl': '1 / 2 / 3 / 3',
            }}
          >
            <Card px="26px" py="30px" w="100%">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">
                Selecciona un módulo
              </Text>
              <Flex position="relative" mb="20px">
                <Select
                  h="50px"
                  variant="main"
                  borderRadius="65px"
                  value={selectedModule ? JSON.stringify(selectedModule) : ''}
                  onChange={handleSelectChange}
                  placeholder="Selecciona una opción"
                  defaultValue={
                    activeModulesWithTitles.length > 0
                      ? JSON.stringify(activeModulesWithTitles[0])
                      : undefined
                  }
                >
                  {activeModulesWithTitles.map((module) => (
                    <option
                      key={module.cod_module}
                      value={JSON.stringify(module)}
                    >
                      {module.displayTitle}
                    </option>
                  ))}
                </Select>

                <IconButton
                  aria-label="share"
                  w="40px"
                  h="40px"
                  variant="brand"
                  borderRadius="50%"
                  position="absolute"
                  top="5px"
                  right="6px"
                >
                  <Icon
                    w="14px"
                    h="14px"
                    as={MdOutlineDoneOutline}
                    color="white"
                  />
                </IconButton>
              </Flex>
            </Card>
          </Grid>
        </Grid>

        <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap="20px" mb="20px">
          <Card
            mt={{ base: '130px', md: '80px', xl: '80px' }}
            mx="auto"
            _hover={
              selectedModule && !userAssigned
                ? {
                    bgGradient: `linear-gradient(115.07deg, #4b3ca7e8 -9.41%, #7e6ee1c2 28.04%, #7e6ee1e8 71.85%, #5648ab91 112.49%, #4433aae8 112.49%)`,
                    bgSize: 'cover',
                    bgPos: 'center',
                  }
                : {}
            }
            cursor={selectedModule && !userAssigned ? 'pointer' : 'not-allowed'}
            opacity={selectedModule && !userAssigned ? 1 : 0.5}
          >
            <Flex direction="column" width="stretch">
              <PrevConfigModule
                title={title}
                desc={desc}
                codProject={cod_project}
                codModule={moduleActive}
                nameModule={nameModule}
                displayTitleModule={displayTitleModule}
                userAssigned={userAssigned}
                nameCompany={nameCompany}
              />
            </Flex>
          </Card>

          <Card
            mt={{ base: '130px', md: '80px', xl: '80px' }}
            mx="auto"
            _hover={
              selectedModule && !userAssigned
                ? {
                    bgGradient: `linear-gradient(115.07deg, #4b3ca7e8 -9.41%, #7e6ee1c2 28.04%, #7e6ee1e8 71.85%, #5648ab91 112.49%, #4433aae8 112.49%)`,
                    bgSize: 'cover',
                    bgPos: 'center',
                  }
                : {}
            }
            cursor={selectedModule && !userAssigned ? 'pointer' : 'not-allowed'}
            opacity={selectedModule && !userAssigned ? 1 : 0.5}
          >
            <Flex direction="column" width="stretch">
              <ConfigModule
                title={title}
                desc={desc}
                codProject={cod_project}
                codModule={moduleActive}
                nameModule={nameModule}
                displayTitleModule={displayTitleModule}
                userAssigned={userAssigned}
                nameCompany={nameCompany}
              />
            </Flex>
          </Card>

          <Card
            mt={{ base: '130px', md: '80px', xl: '80px' }}
            mx="auto"
            _hover={
              selectedModule && !userAssigned
                ? {
                    bgGradient: `linear-gradient(115.07deg, #4b3ca7e8 -9.41%, #7e6ee1c2 28.04%, #7e6ee1e8 71.85%, #5648ab91 112.49%, #4433aae8 112.49%)`,
                    bgSize: 'cover',
                    bgPos: 'center',
                  }
                : {}
            }
            cursor={selectedModule && !userAssigned ? 'pointer' : 'not-allowed'}
            opacity={selectedModule && !userAssigned ? 1 : 0.5}
          >
            <Flex direction="column" width="stretch">
              <RevisionModule
                title={title}
                desc={desc}
                codProject={cod_project}
                codModule={moduleActive}
                nameModule={nameModule}
                displayTitleModule={displayTitleModule}
                userAssigned={userAssigned}
                nameCompany={nameCompany}
              />
            </Flex>
          </Card>
        </SimpleGrid>

        <Grid w="100%" mb="20px" gap="20px" justifyContent="center">
          <Earn
            me="20px"
            gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}
          />
        </Grid>
      </Box>
    </>
  );
}
