import MobileMenu from './mobile-menu';
import {
  Button,
  Icon,
  useColorMode,
  useColorModeValue,
  Box,
  Flex,
  Link,
  UnorderedList,
  ListItem,
  Image,
} from '@chakra-ui/react';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import useAuth from 'hooks/useAuth';
import logoHRBlack from 'assets/logos/smart-black.png';
import logoHRWhite from 'assets/logos/smart-white.png';

export default function Header() {
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white');
  const navbarBg = useColorModeValue('lightbranding.600', '#111c44');

  const { token } = useAuth();

  return (
    <Box
      as="header"
      w="full"
      zIndex="30"
      position="fixed"
      top="0"
      bg={navbarBg}
    >
      <Box maxW="6xl" mx="auto" px={{ base: 4, sm: 6 }}>
        <Flex alignItems="center" justifyContent="space-between" h="20">
          <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
            <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
              <Image
                src={colorMode === 'light' ? logoHRBlack : logoHRWhite}
                width={180}
                height={'auto'}
                alt="Logo"
              />
            </Flex>
          </Flex>

          {/* Navbar links */}
          <Flex display={{ base: 'none', md: 'flex' }} flexGrow={1}>
            <UnorderedList
              display="flex"
              flexGrow={1}
              justifyContent="flex-end"
              alignItems="center"
              listStyleType="none"
              m={0}
              p={0}
            >
              <ListItem>
                <Link
                  href="#home"
                  fontWeight="medium"
                  px={4}
                  py={3}
                  _hover={{
                    textDecoration: 'none',
                    color: 'lightbranding.200',
                  }}
                  onClick={(e) => {
                    e.preventDefault(); // Previene la recarga de la página
                    const section = document.querySelector('#home');
                    section?.scrollIntoView({ behavior: 'smooth' }); // Desplazamiento suave hacia la sección
                  }}
                >
                  Inicio
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="#services"
                  fontWeight="medium"
                  px={4}
                  py={3}
                  _hover={{
                    textDecoration: 'none',
                    color: 'lightbranding.200',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    const section = document.querySelector('#services');
                    section?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Servicios
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="#benefist"
                  fontWeight="medium"
                  px={4}
                  py={3}
                  _hover={{
                    textDecoration: 'none',
                    color: 'lightbranding.200',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    const section = document.querySelector('#benefist');
                    section?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Beneficios
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="#testimonials"
                  fontWeight="medium"
                  px={4}
                  py={3}
                  _hover={{
                    textDecoration: 'none',
                    color: 'lightbranding.200',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    const section = document.querySelector('#testimonials');
                    section?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Reseñas
                </Link>
              </ListItem>

              <ListItem>
                {token ? (
                  <Link
                    href="#/pages/list-projects"
                    fontWeight="medium"
                    px={4}
                    py={3}
                    _hover={{
                      textDecoration: 'none',
                      color: 'lightbranding.200',
                    }}
                  >
                    Panel principal
                  </Link>
                ) : (
                  <Link
                    href="#/auth/sign-in"
                    fontWeight="medium"
                    px={4}
                    py={3}
                    _hover={{
                      textDecoration: 'none',
                      color: 'lightbranding.200',
                    }}
                  >
                    Iniciar sesión
                  </Link>
                )}
              </ListItem>
              <ListItem>
                <Button
                  variant="ghost"
                  bg="darkbrading.100"
                  p="0px"
                  minW="unset"
                  minH="unset"
                  h="25px"
                  w="max-content"
                  onClick={toggleColorMode}
                >
                  <Icon
                    m="10px"
                    h="18px"
                    w="18px"
                    color={navbarIcon}
                    as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
                  />
                </Button>
              </ListItem>
            </UnorderedList>
          </Flex>
          <MobileMenu />
        </Flex>
      </Box>
    </Box>
  );
}
