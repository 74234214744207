import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ModalHeader,
  Spinner,
  Alert,
  AlertIcon,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader as ModalTitle,
} from '@chakra-ui/react';
import useAuth from 'hooks/useAuth';
import {
  assignModulesProjects,
  assignUsersToModules,
  getModulesAvailables,
  getModulesTheProjects,
} from 'services/Modules';
import { AllModules, ModuleProjects } from 'types/Module';
import { getAllUsersCompany } from 'services/User';
import { Users } from 'types/Auth';
import ModuleRow from './ContentRows';

type ModalContentModules = {
  onClosePrimary: () => void;
  codProject: number;
  titleProject: string;
  descProject: string;
  imageProject: string;
  nameCompany: string;
};

export const ModulesContent: React.FC<ModalContentModules> = ({
  onClosePrimary,
  codProject,
  titleProject,
  descProject,
  imageProject,
  nameCompany,
}) => {
  const [modulesData, setModulesProjects] = useState<ModuleProjects[]>([]);
  const [allModules, setAllModules] = useState<AllModules[]>([]);
  const [selectedUsersCompany, setSelectedUsersCompany] = useState<Users[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { user } = useAuth();
  const [codeModule, setCodeModule] = useState<number>(0);
  const [/* codeCustomer, */, setCodeCustomer] = useState<string>('');
  const [typeRow, setRequestType] = useState<string>('');
  const [selectedUsersPayload, setSelectedUsersPayload] = useState<any[]>([]); // State to store selected users for each module

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const allUsersCompany = async () => {
    try {
      const { data } = await getAllUsersCompany(nameCompany);
      setSelectedUsersCompany(data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const getInfoCustomer = async (
    codModule: number,
    requestType: string,
    codeUser: string,
  ) => {
    setCodeModule(codModule);
    setCodeCustomer(codeUser);
    setRequestType(requestType);
    onConfirmOpen();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.role_name]);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const modulesProjectsData = await getModulesTheProjects(codProject);
      const allModulesData = await getModulesAvailables();

      setModulesProjects(modulesProjectsData);
      setAllModules(allModulesData);
      allUsersCompany();
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setSelectedUsersPayload([]);
    onConfirmClose();
  };


  const isModuleAssigned = (moduleCod: number) => {
    return modulesData.some((module) => module.cod_module === moduleCod);
  };

  const handleUsersSelected = (payload: {
    module: { cod_project: number; cod_module: number; cod_user: string }[];
  }) => {
    setSelectedUsersPayload((prev) => [...prev, ...payload.module]);
    setRequestType('user');

    onConfirmOpen();
  };

  const assignUsersForModule = async () => {
    if (typeRow === 'user') {
      try {
        const userAssignments = selectedUsersPayload.map((userAssignment) => ({
          cod_project: codProject,
          cod_module: userAssignment.cod_module,
          cod_user: userAssignment.cod_user,
        }));

        const payload = { module: userAssignments };

        await assignUsersToModules(payload);
        handleClose();
      } catch (error) {
        console.error('Error assigning users to modules:', error);
      }
    } else {
      try {
        const moduleAssignments = [
          {
            cod_project: codProject,
            cod_module: codeModule,
          },
        ];
        const payload = { module: moduleAssignments };
        await assignModulesProjects(payload);
        fetchData();
        handleClose();
      } catch (error) {
        console.error('Error assigning users to modules:', error);
      }
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        No existen datos
      </Alert>
    );
  }

  return (
    <>
      <ModalHeader>
        <Flex
          overflowX="auto"
          whiteSpace="nowrap"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box
            bgImage={`url(${imageProject})`}
            bgSize="cover"
            bgRepeat="no-repeat"
            minW={{ base: '100%' }}
            minH={{ base: '200px', xl: '100px' }}
            borderRadius="100px"
            me="34px"
          />
        </Flex>
      </ModalHeader>

      <ModalBody>
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Codigo:</Th>
                <Th>Nombre del modulo:</Th>
                <Th>Estado:</Th>
                <Th>Asignar usuarios:</Th>
              </Tr>
            </Thead>
            <Tbody>
              {allModules.length === 0 ? (
                <Tr key="no-modules">
                  <Td colSpan={4}>
                    <Text textAlign="center">
                      No existen modulos disponibles
                    </Text>
                  </Td>
                </Tr>
              ) : (
                allModules.map((module) => (
                  <ModuleRow
                    key={module.cod_module}
                    codProject={codProject}
                    module={module}
                    nameCompany={nameCompany}
                    selectedUsersCompany={selectedUsersCompany}
                    isModuleAssigned={isModuleAssigned}
                    getInfoCustomer={getInfoCustomer}
                    onUsersSelected={handleUsersSelected}
                  />
                ))
              )}
            </Tbody>
          </Table>
        </Box>
      </ModalBody>

      <ModalFooter>
        <Flex
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Button
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            px="24px"
            py="5px"
            sx={{ flex: '1 1 auto', margin: '10px 2px', width: '15' }}
            onClick={onClosePrimary}
          >
            Cerrar
          </Button>
        </Flex>
      </ModalFooter>
      <Modal isOpen={isConfirmOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalTitle>Confirmar cambios</ModalTitle>
          <ModalBody>
            <Text>
              {typeRow === 'user'
                ? '¿Desea agregar los usuario(s) al modulo?'
                : '¿Desea agregar el modulo?'}
            </Text>

            <Flex justify="flex-end" mt={4}>
              <Button
                variant="ghost"
                sx={{ flex: '1 1 auto', margin: '10px 2px', width: '15' }}
                mr={3}
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                variant="darkBrand"
                sx={{ flex: '1 1 auto', margin: '10px 2px', width: '15' }}
                onClick={assignUsersForModule}
              >
                Confirmar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
