import React, { useEffect, useRef, useState } from 'react';
import {
  Text,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  ModalFooter,
  useToast,
  ModalCloseButton,
  Image,
  TableContainer,
  TabList,
  Tab,
  Tabs,
  useColorModeValue,
  Portal,
  Flex,
} from '@chakra-ui/react';

import { CheckCircleIcon } from '@chakra-ui/icons';
import { FieldsUpdate, HeaderSheet, RolesUpdate } from 'types/Template';
import { finishSheetByCode, getSheertTemplateById } from 'services/Sheert';
import { updateFields } from 'services/Fields';
import { finishTemplateByName } from 'services/Template';
import useAuth from 'hooks/useAuth';
import { TableSheets } from './modules/TableSheets';
import { useNavigate } from 'react-router-dom';

type StepsProps = {
  nameModule: string;
  onClosePrimary?: () => void;
  setStatusTemplate?: any;
  codeTemplate?: number;
  codProject?: number;
  rolUser?: string;
  companyCustomer?: string;
  codModule?: number;
  headersModule?: (details: HeaderSheet[]) => void;
  cardRef: any;
  isFullscreenModule: boolean;
  title: string;
  desc: string;
};

export const Sheets: React.FC<StepsProps> = ({
  nameModule,
  onClosePrimary,
  setStatusTemplate,
  codeTemplate,
  rolUser,
  companyCustomer,
  codProject,
  codModule,
  headersModule,
  cardRef,
  isFullscreenModule,
  title,
  desc,
}) => {
  const { user } = useAuth();
  const toast = useToast();
  const {
    isOpen: isFinalizeTemplateOpen,
    onOpen: onFinalizeTemplateOpen,
    onClose: onFinalizeTemplateClose,
  } = useDisclosure();
  const [templateUserDetails, setTemplateUserDetails] = useState<HeaderSheet[]>(
    [],
  );
  const [selectedSheetId, setSelectedSheetId] = useState<number>(0);
  const buttonsRef = useRef<(HTMLButtonElement | null)[]>([]);
  const [isFinishTemplate, setIsFinishTemplate] = useState<boolean>(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [isFinishConfirmationOpen, setIsFinishConfirmationOpen] =
    useState(false);
  const [finishSection, setFinishSection] = useState(false);
  const [isSheetFinished, setIsSheetFinished] = useState<boolean>(false);
  const [updatedFieldsData, setUpdatedFieldsData] = useState<FieldsUpdate[]>(
    [],
  );

  const [updatedRolesData, setUpdatedRolesData] = useState<RolesUpdate[]>([]);

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorTertiary = useColorModeValue(
    'secondaryGray.600',
    'secondaryGray.500',
  );

  const navigate = useNavigate();

  const bgModal = useColorModeValue('#ffffff', 'navy.800');

  const getSheertById = async () => {
    try {
      if (rolUser === 'superadmin') {
        // Lógica para el superadmin
      } else {
        const { data } = await getSheertTemplateById(codModule, codProject);
  
        if (Array.isArray(data)) {
          // Ordenar los datos por cod_sheert
          const sortedData = data.sort((a, b) => a.cod_sheert - b.cod_sheert);
  
          // Eliminar duplicados
          const uniqueData = Array.from(
            new Map(sortedData.map((item) => [item.cod_sheert, item])).values(),
          );
  
          setTemplateUserDetails(uniqueData);
  
          const allSectionsFinished = uniqueData.every(
            (sheet) => sheet.finalized,
          );
          setIsFinishTemplate(allSectionsFinished);
  
          const initialSheet = uniqueData.find((sheet) => !sheet.finalized);
          setSelectedSheetId(initialSheet ? initialSheet.cod_sheert : null);
        } else {
          console.error('Los datos obtenidos no son un array.');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  useEffect(() => {
    getSheertById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameModule, user]);

  useEffect(() => {
    if (templateUserDetails.length > 0) {
      headersModule?.(templateUserDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateUserDetails]);

  useEffect(() => {
    const firstUnfinishedIndex = templateUserDetails?.findIndex(
      (sheet) => !sheet.finalized,
    );

    if (
      firstUnfinishedIndex !== undefined &&
      firstUnfinishedIndex !== -1 &&
      buttonsRef.current[firstUnfinishedIndex]
    ) {
      buttonsRef.current[firstUnfinishedIndex]?.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
      });
    }
  }, [templateUserDetails]);

  const openSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const openFinishConfirmation = () => {
    setIsFinishConfirmationOpen(true);
  };

  const closeSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
    setIsFinishConfirmationOpen(false);
  };

  const saveWithConfirmation = () => {
    closeSaveConfirmation();
    saveTemplateUser();
  };

  const saveWithFinish = () => {
    closeSaveConfirmation();
    finishSheet();
  };

  const handleSheetChange = (cod_sheert: number) => {
    setSelectedSheetId(cod_sheert);
  };

  const handleSectionFinish = async () => {
    await getSheertById();
  };

  const handleDisableSectionFinish = (finalized: boolean) => {
    setIsSheetFinished(finalized);
  };

  const saveTemplateUser = async () => {
    try {
      let fieldsData;
      let rolesData;

      if (updatedFieldsData.length > 0) {
        fieldsData = updatedFieldsData.map((field) => ({
          cod_config: field.cod_config,
          Value: field.value,
        }));
      } else {
        fieldsData = [
          {
            cod_config: 0,
            Value: '',
          },
        ];
      }

      if (updatedRolesData.length > 0) {
        rolesData = updatedRolesData.map((role) => ({
          cod_permission: role.cod_permission,
          Value: role.value,
        }));
      } else {
        rolesData = [
          {
            cod_permission: 0,
            Value: '',
          },
        ];
      }

      updateFields(fieldsData, rolesData, codModule, codProject)
        .then((response) => {
          toast({
            title: 'Datos actualizados correctamente',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          console.error('Error al actualizar los datos:', error);
        });
    } catch (error) {
      console.error('Error al guardar la plantilla:', error);
      toast({
        title: 'Error',
        description: 'Hubo un error al guardar la plantilla.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const finishSheet = async () => {
    try {
      if (!codModule) {
        throw new Error('El proyecto es requerido');
      }

      const data: {
        cod_sheert: number;
        cod_project: number;
        finalized: boolean;
      } = {
        cod_sheert: selectedSheetId,
        cod_project: codProject,
        finalized: true,
      };
      await finishSheetByCode(data, codModule);
    } catch (error) {
      console.error('Error al finalizar la plantilla:', error);
    } finally {
      handleSectionFinish();
    }
  };

  const finalizeTemplateUser = async () => {
    try {
      const data: {
        cod_project: number;
        finalized: boolean;
      } = {
        cod_project: codProject,
        finalized: true,
      };
      await finishTemplateByName(data, codModule);
    } catch (error) {
      console.error('Error al finalizar el módulo:', error);
    } finally {
      onFinalizeTemplateClose();
      setStatusTemplate(isFinishTemplate);
    }
  };

  const handleClick = (
    nameModule: string,
    codProject: number,
    title: string,
    desc: string,
  ) => {
    const params = {
      cod_project: codProject,
      title: title,
      desc: desc,
    };

    navigate('/pages/modules', { state: params });
  };

  /* const { title, desc, cod_project } = location.state || {}; */

  return (
    <>
      <Box
        me={{ md: '40px', '3xl': '40px' }}
        position="relative"
        display="flex"
        flexDirection="column"
        height={isFullscreenModule ? '100vh' : '100%'}
      >
        <Tabs
          variant="soft-rounded"
          colorScheme="brandTabs"
          mb="15px"
          mt={isFullscreenModule ? '2rem' : '5px'}
        >
          <TabList
            style={{
              display: 'flex',
              width: '100px',
              minWidth: '-webkit-fill-available',
              flexWrap: 'nowrap',
              overflowX: 'auto',
              padding: '0 20px',
            }}
          >
            {templateUserDetails.map((sheet) => (
              <Tab
                key={sheet.cod_sheert}
                onClick={() => handleSheetChange(sheet.cod_sheert)}
                bg="unset"
                ref={(el) => (buttonsRef.current[sheet.cod_sheert] = el)}
                _selected={{ bg: 'none' }}
                _focus={{ border: 'none' }}
                minW="max-content"
                display="flex"
                flexDirection="column"
                alignItems="center"
                px={4}
                py={2}
                borderRadius="md"
                _hover={{
                  bg:
                    sheet.cod_sheert === selectedSheetId
                      ? 'blue.500'
                      : 'gray.200',
                }}
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Text
                    color={
                      sheet.cod_sheert === selectedSheetId
                        ? textColor
                        : textColorTertiary
                    }
                    fontSize="lg"
                    fontWeight="500"
                    mr={sheet.finalized ? 2 : 0}
                  >
                    {sheet.title}
                  </Text>
                  {sheet.finalized && (
                    <CheckCircleIcon
                      color="green.500"
                      boxSize={4}
                      ml={1} // Margen izquierdo para separar el icono del texto
                    />
                  )}
                </Box>

                <Box
                  height="4px"
                  w="100%"
                  transition="0.1s linear"
                  bg={
                    sheet.cod_sheert === selectedSheetId
                      ? 'brand.500'
                      : 'transparent'
                  }
                  mt="15px"
                  borderRadius="30px"
                />
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Box
          flex="1"
          overflow="auto"
          display="flex"
          flexWrap="wrap"
          alignContent="center"
        >
          {selectedSheetId &&
            !finishSection &&
            templateUserDetails?.find(
              (sheet) => sheet.cod_sheert === selectedSheetId,
            ) && (
              <TableSheets
                codSheet={selectedSheetId}
                isFinishedSection={
                  templateUserDetails.find(
                    (sheet) => sheet.cod_sheert === selectedSheetId,
                  )?.finalized || false
                }
                onSectionFinish={handleSectionFinish}
                isFinishTemplate={isFinishTemplate}
                setFinishSection={setFinishSection}
                setFinishSheet={handleDisableSectionFinish}
                nameModule={nameModule}
                setFieldsUpdatedToTable={setUpdatedFieldsData}
                setRolesUpdatedToTable={setUpdatedRolesData}
                companyCustomer={companyCustomer}
                rolUser={rolUser}
                codModule={codModule}
                codProject={codProject}
                cardRef={cardRef}
                isFullscreenModule={isFullscreenModule}
              />
            )}

          {isFinishTemplate && !finishSection && selectedSheetId === null && (
            <TableContainer
              style={{
                maxHeight: '600px',
                overflowY: 'auto',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                marginBottom: '6rem',
              }}
            >
              <Box
                boxSize="sm"
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                justifyContent="center"
              >
                {rolUser === 'customer' && (
                  <>
                    <Text>Has completado todas las secciones.</Text>
                    <Text style={{ fontWeight: 'bold ' }}>
                      ¿Desea finalizar la plantilla?
                    </Text>
                  </>
                )}
                {rolUser === 'superadmin' && (
                  <>
                    <Text>El cliente ha finalizado todas las secciones.</Text>
                  </>
                )}
                <Image
                  src={require(`assets/img/dashboards/finish-sections.png`)}
                  alt="Plantilla Finalizada"
                />
              </Box>
            </TableContainer>
          )}
        </Box>
        <Flex
          position="absolute"
          bottom={isFullscreenModule ? '3rem' : '5px'}
          width="100%"
          justifyContent="space-between"
          flexWrap="wrap"
          p={{ base: '5px', md: '10px' }} // Ajustar padding en base y md
          flexDirection={{ base: 'column', md: 'row' }} // Cambia a columna en pantallas pequeñas
        >
          {rolUser === 'customer' || rolUser === 'admin' ? (
            <>
              <Button
                flex="1"
                m="5px" // Ajustar margen
                onClick={() => handleClick(nameModule, codProject, title, desc)}
              >
                Regresar
              </Button>
              <Button
                flex="1"
                m="5px" // Ajustar margen
                onClick={openSaveConfirmation}
                isDisabled={
                  isSheetFinished ||
                  isFinishTemplate ||
                  (!updatedFieldsData.length && !updatedRolesData.length)
                }
              >
                Guardar
              </Button>
              <Button
                flex="1"
                m="5px" // Ajustar margen
                onClick={openFinishConfirmation}
                isDisabled={isSheetFinished || isFinishTemplate}
              >
                Finalizar sección
              </Button>
              {isFinishTemplate === false && (
                <Button
                  flex="1"
                  m="5px" // Ajustar margen
                  colorScheme="red"
                  onClick={onFinalizeTemplateOpen}
                  isDisabled={!isFinishTemplate}
                >
                  Finalizar plantilla
                </Button>
              )}
            </>
          ) : (
            rolUser === 'superadmin' && (
              <Button flex="1" m="5px" onClick={onClosePrimary}>
                Cerrar
              </Button>
            )
          )}
        </Flex>
      </Box>
      <Portal containerRef={cardRef}>
        <Modal
          isOpen={isFinalizeTemplateOpen}
          onClose={onFinalizeTemplateClose}
        >
          <ModalOverlay />
          <ModalContent p={6} bg={bgModal}>
            <ModalHeader>Finalizar Plantilla</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                ¿Está seguro de que desea finalizar la plantilla? Si lo hace, no
                podrá volver a editarla.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="red"
                variant="solid"
                onClick={() => {
                  onClosePrimary();
                  finalizeTemplateUser();
                }}
              >
                Aceptar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Portal>
      <Portal containerRef={cardRef}>
        <Modal
          isOpen={isFinishConfirmationOpen}
          onClose={closeSaveConfirmation}
        >
          <ModalOverlay />
          <ModalContent p={6} bg={bgModal}>
            <ModalHeader>Finalizar sección</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                ¿Está seguro de finalizar la sección? una vez finalizada no
                podrá editarla.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button onClick={closeSaveConfirmation} variant="secondary">
                Cancelar
              </Button>
              <Button colorScheme="blue" onClick={saveWithFinish} ml={3}>
                Finalizar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Portal>
      <Portal containerRef={cardRef}>
        <Modal isOpen={isSaveConfirmationOpen} onClose={closeSaveConfirmation}>
          <ModalOverlay />
          <ModalContent p={6} bg={bgModal}>
            <ModalHeader>Confirmación de Guardar</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>¿Está seguro de guardar la plantilla?</Text>
            </ModalBody>
            <ModalFooter>
              <Button onClick={closeSaveConfirmation} variant="secondary">
                Cancelar
              </Button>
              <Button colorScheme="blue" onClick={saveWithConfirmation} ml={3}>
                Confirmar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Portal>
    </>
  );
};

export default Sheets;
