// Chakra imports
import { Box, Button, Grid, useColorModeValue } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
/* import { useReactToPrint } from 'react-to-print'; */
import CardDocument from './components/CardDocuments';
import ViewDocumentPreConfig from './components/ViewDocument';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

export default function RevisionDetailModule() {
  const textColor = useColorModeValue('gray.700', 'white');
  const bgButton = 'rgba(255,255,255,0.12)';
  const bgHover = { bg: 'whiteAlpha.50' };
  const bgFocus = { bg: 'rgba(255,255,255,0.12)' };

  const componentRef = useRef();

  const location = useLocation();
  const {
    cod_project,
    cod_module,
    title,
    desc,
    nameModule,
    titleModule,
    nameCompany,
  } = location.state || {};

  const [selectedDocument, setSelectedDocument] = useState({
    title: '',
    description: '',
    file_urls: [],
  });

  const handleDocumentSelect = (document: {
    title: string;
    description: string;
    file_urls: string[];
  }) => {
    setSelectedDocument(document);
  };

  const navigate = useNavigate();

  const handleClick = (
    cod_project: number,
    cod_module: number,
    title: string,
    desc: string,
    nameModule: string,
    titleModule: string,
    nameCompany: string,
  ) => {
    const params = {
      cod_project: cod_project,
      cod_module: cod_module,
      title: title,
      desc: desc,
      nameModule: nameModule,
      titleModule: titleModule,
      nameCompany: nameCompany,
    };

    navigate('/pages/modules', { state: params });
  };

  return (
    <Box>
      <Button
        mt={{ base: '130px', md: '80px', xl: '80px' }}
        onClick={() =>
          handleClick(
            cod_project,
            cod_module,
            title,
            desc,
            nameModule,
            titleModule,
            nameCompany,
          )
        }
        width={100}
        colorScheme="brand"
        leftIcon={<MdArrowBack />}
      >
        Atrás
      </Button>
      <Grid
        mb="20px"
        templateColumns={{ base: '2.4fr 1fr', lg: '2.4fr 1fr' }}
        flexDirection="column"
      >
        <CardDocument
          ms={{ base: '0px', lg: '20px' }}
          mt={{ base: '20px', lg: '0px' }}
          zIndex="0"
          gridArea={{ base: '2 / 1 / 3 / 3', lg: '1 / 2 / 2 / 3' }}
          cod_project={cod_project}
          cod_module={cod_module}
          onDocumentSelect={handleDocumentSelect}
          nameCompany={nameCompany}
        />
        <ViewDocumentPreConfig
          me="20px"
          gridArea={{ base: '1 / 1 / 2 / 3', lg: '1 / 1 / 2 / 2' }}
          ref={componentRef}
          textColor={textColor}
          bgButton={bgButton}
          bgHover={bgHover}
          bgFocus={bgFocus}
          title={selectedDocument?.title}
          desc={selectedDocument?.description}
          selectedDocument={selectedDocument}
          nameCompany={nameCompany}
        />
      </Grid>
    </Box>
  );
}
