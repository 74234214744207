// Chakra imports
import {
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdTimer,  } from 'react-icons/md';
import React from 'react';
import {
  BsCheckCircle,
  BsCheckCircleFill,
} from 'react-icons/bs';

export default function ListDocuments(props: {
  quest?: any;
  data?: any;
  date?: string;
  sum?: string;
  icon: JSX.Element;
  status?: string;
  name: string;
  BlockItems?: any;
  listBlockItems?: any;
  listQuest?: any;
  [x: string]: any;
  handleGet?: any;
  fetchBlocks?: any;
  // handleTargetQuest?:any;
  handleTargetQuest?: (T: any, A: any) => void;
  id?: any;
  updateExtensiveNavigation?: (id: any) => void;
  extensiveNavigation?: number | null;
  setQuestTabState?: any;
  questTabState?: any;
  deleteQuest?: any;
  delSeq?: any;
  tabNo?: any;
}) {
  const {
    date,
    sum,
    icon,
    status,
    name,
    quest,
    data,
    BlockItems,
    listBlockItems,
    listQuest,
    id,
    handleTargetQuest,
    handleGet,
    fetchBlocks,
    setQuestTabState,
    questTabState,
    deleteQuest,
    delSeq,
    tabNo,
    ...rest
  } = props;

  /* const navigate = useNavigate(); */

  /* const textColor = useColorModeValue('black', 'black'); */
  const iconColor = useColorModeValue('secondaryGray.600', 'white');
  /* const inActive = useColorModeValue('gray.50', 'white'); */

  /* const handleMouseOver = (e: any) => {

		e.currentTarget.style.boxShadow = '0 0 20px rgba(128, 128, 128, 0.9)';
	}; */

  /* const handleMouseOut = (e: any) => {

		e.currentTarget.style.boxShadow = 'none';
	}; */

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        w="100%"
        zIndex="2"
        {...rest}
        className="OrderStep"
        id={tabNo === 4 ? 'taby4' : `tab${tabNo}`}
        title={status}
      >
        {icon}
        <Flex direction="column" align="start" ms="20px" mr="auto">
          <Text fontSize="lg" me="6px" fontWeight="600">
            {name}
          </Text>

          <Text color="secondaryGray.600" fontSize="md" fontWeight="400">
            {date}
          </Text>
        </Flex>
        {status === 'checked' ? (
          <Flex
            align="center"
            justify="center"
            h="30px"
            w="30px"
            borderRadius="50%"
          >
            <Icon
              borderRadius={'50%'}
              border="1px solid"
              h="27px"
              borderColor={'green.500'}
              w="29px"
              bgColor={'transparent'}
              as={BsCheckCircleFill}
              color="green.500"
            />
          </Flex>
        ) : status === 'unchecked' ? (
          <Flex
            align="center"
            justify="center"
            h="30px"
            w="30px"
            borderRadius="50%"
            border="2px solid"
            borderColor="gray.500"
          >
            <Icon h="18px" w="18px" as={BsCheckCircle} color="gray.500" />
          </Flex>
        ) : (
          <Icon h="34px" w="34px" color={iconColor} as={MdTimer} />
        )}
      </Flex>
    </>
  );
}
