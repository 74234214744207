// Chakra imports
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  FormControl,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';

// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import React, { useEffect, useState } from 'react';
import banner from 'assets/img/product/OverviewBanner.png';

// Assets
import Upload from 'components/dropzone/Upload';
import { createProject } from 'services/Projects';
import useAuth from 'hooks/useAuth';
import { Companies } from 'types/Company';
import { getCompaniesByCustomers } from 'services/Company';

export default function NewProject() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [activeBullets, setActiveBullets] = useState({
    product: true,
    media: false,
    pricing: false,
  });
  const [selectedBanner, setSelectedBanner] = useState<File | null>(null);

  const infoTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const mediaTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const confirmTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const [companyCustomer, setCompanyCustomer] = useState<Companies[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>('');

  const { user } = useAuth();

  useEffect(() => {
    if (user.role_name === 'superadmin') {
      getAllCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.role_name]);

  const getAllCompanies = async () => {
    try {
      const { data } = await getCompaniesByCustomers();
      setCompanyCustomer(data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const handleCompanySelect = (name_company: string) => {
    setSelectedCompany(name_company);
  };

  const handleBannerChange = (file: File | null) => {
    if (file) {
      if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
        alert(
          'Por favor, selecciona un archivo de imagen válido (JPG, JPEG, PNG)',
        );
        return;
      }
      setSelectedBanner(file);
    }
  };

  const onSubmit = async (values: any) => {
    try {
      const formData = new FormData();
      if (user.role_name === 'superadmin') {
        formData.append('name_company', selectedCompany);
      }

      formData.append('name', values.name);
      formData.append('description_project', values.description_project);
      formData.append('start_date', values.start_date);
      formData.append('end_date', values.end_date);
      formData.append('image', selectedBanner);

      await createProject(formData);
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  const validatePanel = async (tab: string) => {
    const isValidForm = await trigger();
    if (isValidForm) {
      if (tab === 'info') mediaTab.current.click();
      if (tab === 'media') confirmTab.current.click();
    }
  };

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: '125px', lg: '75px' }}
      position="relative"
    >
      <Box
        h="45vh"
        bgImage={banner}
        position="absolute"
        w="100%"
        borderRadius="20px"
        bgSize="cover"
        mb="20px"
      />

      <Tabs
        variant="unstyled"
        mt={{ base: '60px', md: '165px' }}
        zIndex="0"
        display="flex"
        flexDirection="column"
      >
        <TabList
          display="flex"
          alignItems="center"
          alignSelf="center"
          justifySelf="center"
        >
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={infoTab}
            w={{ sm: '120px', md: '250px', lg: '300px' }}
            onClick={() =>
              setActiveBullets({
                product: true,
                media: false,
                pricing: false,
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: '120px', md: '250px', lg: '300px' },
                height: '3px',
                bg: activeBullets.media ? 'white' : 'brand.400',
                left: { sm: '12px', md: '40px' },
                top: {
                  sm: activeBullets.product ? '6px' : '4px',
                  md: null,
                },
                position: 'absolute',
                bottom: activeBullets.product ? '40px' : '38px',
                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.product ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.product ? 'white' : 'gray.300'}
                fontWeight={activeBullets.product ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Información
              </Text>
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={mediaTab}
            w={{ sm: '120px', md: '250px', lg: '300px' }}
            onClick={() =>
              setActiveBullets({
                product: true,
                media: true,
                pricing: false,
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: '120px', md: '250px', lg: '300px' },
                height: '3px',
                bg: activeBullets.pricing ? 'white' : 'brand.400',
                left: { sm: '12px', md: '28px' },
                top: '6px',
                position: 'absolute',
                bottom: activeBullets.media ? '40px' : '38px',
                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.media ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.media ? 'white' : 'gray.300'}
                fontWeight={activeBullets.media ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Imagen
              </Text>
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={confirmTab}
            w={{ sm: '120px', md: '250px', lg: '300px' }}
            onClick={() =>
              setActiveBullets({
                product: true,
                media: true,
                pricing: true,
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.pricing ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.pricing ? 'white' : 'gray.300'}
                fontWeight={activeBullets.pricing ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Confirmar
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Información del proyecto
              </Text>
              <Flex direction="column" w="100%">
                <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" alignItems='end'>
                  <Stack direction="column" spacing="20px">
                    {user.role_name === 'superadmin' && (
                      <FormControl isInvalid={!!errors.company}>
                        <FormLabel htmlFor="company">Compañia</FormLabel>
                        <Controller
                          name="company"
                          control={control}
                          rules={{
                            required: 'Seleccionar una compañía es requerido',
                          }}
                          render={({ field }) => (
                            <Select
                              placeholder="Seleccione una compañía"
                              {...field}
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                field.onChange(selectedValue); // Updates the form state
                                handleCompanySelect(selectedValue); // Update the selected company state
                              }}
                            >
                              {companyCustomer.map((company) => (
                                <option key={company.name} value={company.name}>
                                  {company.name}
                                </option>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    )}
                    <FormControl isInvalid={!!errors.name}>
                      <FormLabel htmlFor="name">Nombre del proyecto</FormLabel>
                      <Controller
                        name="name"
                        control={control}
                        rules={{ required: 'Nombre del proyecto es requerido' }}
                        render={({ field }) => (
                          <InputField
                            id="name"
                            placeholder="Nombre del proyecto"
                            {...field}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.start_date}>
                      <FormLabel htmlFor="start_date">
                        Fecha de inicio del proyecto
                      </FormLabel>
                      <Controller
                        name="start_date"
                        control={control}
                        rules={{
                          required: 'Fecha inicio del proyecto es requerido',
                        }}
                        render={({ field }) => (
                          <InputField id="start_date" type="date" {...field} />
                        )}
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing="20px">
                    <FormControl isInvalid={!!errors.description_project}>
                      <FormLabel htmlFor="description_project">
                        Descripción del proyecto
                      </FormLabel>
                      <Controller
                        name="description_project"
                        control={control}
                        rules={{
                          required: 'Descripción del proyecto es requerido',
                        }}
                        render={({ field }) => (
                          <InputField
                            id="description_project"
                            placeholder="Descripción del proyecto"
                            {...field}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.end_date}>
                      <FormLabel htmlFor="end_date">
                        Fecha fin del proyecto
                      </FormLabel>
                      <Controller
                        name="end_date"
                        control={control}
                        rules={{
                          required: 'Fecha final del proyecto es requerido',
                        }}
                        render={({ field }) => (
                          <InputField id="end_date" type="date" {...field} />
                        )}
                      />
                    </FormControl>
                  </Stack>
                </SimpleGrid>

                <Flex direction="row" mt="30px">
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    fontWeight="500"
                    borderRadius="70px"
                    px="24px"
                    py="5px"
                    onClick={() => validatePanel('info')}
                    isDisabled={!isValid}
                  >
                    Siguiente
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Imágenes del proyecto
              </Text>
              <Flex direction="column" w="100%">
                <FormControl isInvalid={!selectedBanner} mt="10px">
                  <FormLabel htmlFor="banner">Imagen del proyecto</FormLabel>
                  <Upload
                    pe="20px"
                    pb={{ base: '100px', lg: '20px' }}
                    onImageSelect={handleBannerChange}
                  />
                </FormControl>
                <Flex direction="row" mt="30px">
                  <Button
                    variant="outline"
                    fontSize="sm"
                    fontWeight="500"
                    borderRadius="70px"
                    px="24px"
                    py="5px"
                    onClick={() => infoTab.current.click()}
                  >
                    Atrás
                  </Button>
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    fontWeight="500"
                    borderRadius="70px"
                    px="24px"
                    py="5px"
                    onClick={() => validatePanel('media')}
                    isDisabled={!selectedBanner}
                  >
                    Siguiente
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Confirmar detalles
              </Text>
              <Flex direction="column" w="100%">
                <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                  <Stack direction="column" spacing="20px">
                    <Text>¿Está seguro de crear este proyecto?</Text>
                  </Stack>
                </SimpleGrid>
                <Flex direction="row" mt="30px">
                  <Button
                    variant="outline"
                    fontSize="sm"
                    fontWeight="500"
                    borderRadius="70px"
                    px="24px"
                    py="5px"
                    onClick={() => mediaTab.current.click()}
                  >
                    Atrás
                  </Button>
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    fontWeight="500"
                    borderRadius="70px"
                    px="24px"
                    py="5px"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isSubmitting}
                  >
                    Confirmar
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
