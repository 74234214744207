import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Text,
  Checkbox,
  IconButton,
  Select,
  Button,
  useToast,
  useDisclosure,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons'; // Chakra UI icons
import { useState } from 'react';
import { updateList } from '../../../../../../../services/Fields';

const fieldTypeMap: Record<
  string,
  'text' | 'checkbox' | 'select' | 'input' | 'textarea' | 'selectVisibility'
> = {
  /* EMPLOYEE_CENTRAL */
  'Nombre del campo': 'text',
  'Nombre en plantilla': 'text',
  'tipo de dato': 'text',
  activado: 'checkbox',
  obligatorio: 'checkbox',
  'Etiqueta: Nombre personalizado': 'input',
  Visibilidad: 'selectVisibility',
  'Lista desplegable': 'select',
  'oculto': 'checkbox',
  requerido: 'checkbox',
  'Area de texto activda': 'checkbox',
  /* JPB */
  'ID de flujo de trabajo': 'text',
  Nombre: 'text',
  Descripción: 'text',
  Identificador: 'text',
  'Autorizaciones de usuario': 'text',
  'Objetivo de la sección': 'text',
  'Componentes típicos que pueden incluirse': 'textarea',
  'Sección obligatoria ': 'checkbox',
  'Sección obligatoria': 'checkbox',
  'Sección solo visible para administradores': 'checkbox',
  'Mostrar en solicitud de puesto': 'checkbox',
  'Mostrar en publicación externa': 'checkbox',
  'Mostrar en publicación interna': 'checkbox',
  'Limite de caracteres': 'input',
  '¿Se admite delegar?': 'checkbox',
  'Paso 1 Tipo de aprobador': 'input',
  'Paso 2 Tipo de aprobador': 'input',
  Contribuidores: 'input',
  'Función CC': 'input',
  /* RCM */
  'Nombre de campo': 'text',
  'Nombre de la escala de calificación': 'text',
  'Nombre del grupo': 'text',
  'Nombre de la plantilla': 'text',
  'Usuario principal predeterminado Cargo o posición': 'input',
  'Equipo de trabajo cargo o posicion': 'input',
  'Tipo de Campo ': 'text',
  'Etiqueta de campo': 'text',
  'Fuente ': 'text',
  'Tipo de datos': 'text',
  Función: 'text',
  Idiomas: 'text',
  'Nombre en plantilla ': 'text',
  'Nombre Etiqueta del sistema': 'text',
  Puntuación: 'text',
  Fuente: 'text',
  'Id. de campo': 'text',
  Designador: 'text',
  Etiqueta: 'input',
  'Descripción de la escala': 'text',
  'Nombre en el flujo': 'text',
  Activado: 'checkbox',
  Obligatorio: 'checkbox',
  Editar: 'selectVisibility',
  /* LMS */
  'Area de texto activada': 'checkbox',
  'Oculto': 'checkbox',
  'Campo de carga activado': 'checkbox',
  'Frecuencia del proceso': 'select',
  'Etiqueta: Nombre personalizado del asunto de la notificación': 'checkbox',
  'Destinatario 1': 'select',
  'Destinatario 2': 'select',
  'Etiqueta: Prefijo de ID personalizado': 'select',
  'Añadir Prefijo del sitio': 'select',
  'Seleccionar Orden': 'select'
};

interface Option {
  cod_option: number;
  value: string;
  cod_list?: number;
  cod_id?: number;
}

interface OptionList {
  cod_option: number;
  value: string;
  cod_list: number;
  cod_id: number;
}

interface FieldData {
  name: string;
  value: string | null;
  options?: Option[];
}

export const RenderField = (
  data: FieldData,
  fieldValues: Record<string, any>,
  handleFieldChange: (name: string, value: any) => void,
  nameModule: string,
  codModule: number,
  codProject: number,
) => {
  const toast = useToast();

  const fieldType = fieldTypeMap[data.name];
  const options = data.options || [];
  const hasOptions = options.length > 0;

  const [newOptions, setNewOptions] = useState<Option[]>(data.options || []);

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const handleOptionChange = (index: number, value: string) => {
    const updatedOptions = [...newOptions];
    updatedOptions[index].value = value;
    setNewOptions(updatedOptions);
  };

  const handleSaveOptions = () => {
    handleFieldChange(data.name, true);
    saveOptionsSelect(newOptions);
    onClose2();
  };

  const saveOptionsSelect = async (options: Option[]) => {
    try {
      const optionsData: OptionList[] = options.map((option) => ({
        cod_option: option.cod_option,
        value: option.value,
        cod_list: option.cod_list ?? 0,
        cod_id: option.cod_id ?? 0,
      }));

      const updateData = optionsData.map((option) => ({
        cod_option: String(option.cod_option),
        Value: option.value,
      }));

      await updateList(updateData, codModule, codProject);
      toast({
        title: 'Opciones actualizadas correctamente',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error al actualizar las Opciones:', error);
      toast({
        title: 'Error',
        description: 'Hubo un error al guardar la opciones.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleOptionDelete = (index: number) => {
    setNewOptions(newOptions.filter((_, i) => i !== index));
  };

  const inputVariant = useColorModeValue('gray.900', 'white');
  const optionColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const optionBg = useColorModeValue('white', 'gray.700');

  const renderFieldContent = () => {
    switch (fieldType) {
      case 'textarea':
        return (
          <Input
            size="sm"
            color={inputVariant}
            placeholder="Ingresa "
            value={fieldValues[data.name] || ''}
            onChange={(e) => handleFieldChange(data.name, e.target.value)}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            isChecked={fieldValues[data.name] === 'true'}
            onChange={(e) =>
              handleFieldChange(data.name, e.target.checked ? 'true' : 'false')
            }
            borderColor="gray.300" // Color del borde normal
            _checked={{ borderColor: 'blue.500', bg: 'blue.500' }}
            _focus={{ borderColor: 'blue.600', boxShadow: 'outline' }}
          />
        );
      case 'select':
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              value={fieldValues[data.name] || ''}
              onChange={(e) => handleFieldChange(data.name, e.target.value)}
              style={{ flexGrow: 1 }}
              disabled={!hasOptions}
              sx={{
                option: {
                  color: optionColor,
                  backgroundColor: optionBg,
                },
              }}
            >
              {hasOptions ? (
                options.map((option) => (
                  <option key={option.cod_option} value={option.value}>
                    {option.value}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No hay opciones disponibles
                </option>
              )}
            </Select>
            <IconButton
              aria-label="edit dropdown"
              size="small"
              onClick={onOpen2}
              style={{ marginLeft: 8 }}
              disabled={!hasOptions}
            >
              <EditIcon />
            </IconButton>
          </div>
        );
      case 'selectVisibility':
        return (
          <Select
            value={fieldValues[data.name] || ''}
            onChange={(e) => handleFieldChange(data.name, e.target.value)}
            className="z-index: 2400 !important"
            sx={{
              option: {
                color: optionColor,
                backgroundColor: optionBg,
              },
            }}
          >
            <option value="Editar">Editar</option>
            <option value="Ver">Ver</option>
          </Select>
        );
      case 'input':
        return (
          <Input
            color={inputVariant}
            size="sm"
            placeholder="Ingresa "
            value={fieldValues[data.name] || ''}
            onChange={(e) => handleFieldChange(data.name, e.target.value)}
          />
        );
      case 'text':
        return <Text>{fieldValues[data.name] || ''}</Text>;
      default:
        return null;
    }
  };

  return (
    <>
      {renderFieldContent()}
      <Modal isOpen={isOpen2} onClose={onClose2} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modificar Opciones</ModalHeader>
          <ModalBody>
            {newOptions.map((option, index) => (
              <Stack
                key={option.cod_option}
                direction="row"
                spacing={2}
                align="center"
                mb={2}
              >
                <Input
                  placeholder={`Opción ${index + 1}`}
                  value={option.value}
                  color={inputVariant}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                />
                <IconButton
                  aria-label="delete"
                  onClick={() => handleOptionDelete(index)}
                  size="sm"
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={onClose2}>
              Cancelar
            </Button>
            <Button colorScheme="blue" ml={3} onClick={handleSaveOptions}>
              Guardar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
