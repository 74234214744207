import 'assets/css/App.css';
import React from "react"
import * as ReactDOM from "react-dom/client"
import { ErrorBoundary } from 'components/ErrorBoundary';
import Main from 'routes';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundary>
      <Main />
  </ErrorBoundary>,
);
