import { Flex, Text } from '@chakra-ui/react';
import { DocumentViewer } from 'react-documents';
import { DocumentPreconfiguration } from 'types/Template';

export default function Content(props: { selectedDocument: DocumentPreconfiguration; [x: string]: any }) {
  const { selectedDocument, ...rest } = props;
  const documentUri = selectedDocument?.document;

  const viewer = 'google';
  const viewerUrl = 'https://docs.google.com/gview?url=%URL%&embedded=true';

  return (
    <Flex direction='column' p={{ base: '20px', md: '34px' }} px={{ base: '0px', md: '34px' }} {...rest}>
      {documentUri ? (
        <DocumentViewer
          url={documentUri}
          viewer={viewer}
          viewerUrl={viewerUrl}
          style={{ width: '100%', height: '80vh' }}
        />
      ) : (
        <Text color="red.500">No hay documento seleccionado</Text>
      )}
    </Flex>
  );
}
