// Chakra imports
import {
  Icon,
  Text,
  Flex,
  useColorModeValue,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  SimpleGrid,
  Input,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import IconBox from 'components/icons/IconBox';
// Assets
import ListDocuments from './ListDocuments';
import { CgFileDocument } from 'react-icons/cg';
import { createDocumentRevision, getDocumentsRevision } from 'services/Modules';
import { useEffect, useState } from 'react';
import { DocumentRevision } from 'types/Template';
import { MdAdd } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Upload from 'components/dropzone/Upload';

const schema = yup.object().shape({
  title: yup.string().required('El titulo es requerido'),
  description: yup.string().required('La descripción es requerido'),
});

export default function DocumentsListPreconfiguration(props: {
  cod_project: number;
  cod_module: number;
  nameCompany: string;
  onDocumentSelect: (document: {
    title: string;
    description: string;
    file_urls: string[];
  }) => void;
  [x: string]: any;
}) {
  const { cod_project, cod_module, onDocumentSelect, nameCompany, ...rest } =
    props;
  const [documentRevision, setDocumentsRevision] = useState<DocumentRevision[]>(
    [],
  );
  const [selectedDocument, setSelectedDocument] = useState<number | null>(null);

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const completeBg = useColorModeValue(
    'white',
    'linear-gradient(180deg, #1F2A4F 0%, #18224D 50.63%, #111C44 100%)',
  );
  const completeShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'inset 0px 4px 4px rgba(255, 255, 255, 0.2)',
  );

  const incompleteShadow = useColorModeValue(
    'inset 0px 18px 22px rgba(112, 144, 176, 0.1)',
    'inset 0px 4px 4px #0B1437',
  );
  const lineColor = useColorModeValue('%23a3aed0', '%23FFFFFF1A');

  const getDocumentsInfo = async () => {
    try {
      const { data } = await getDocumentsRevision(
        nameCompany,
        cod_project,
        cod_module,
      );
      setDocumentsRevision(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelect = (index: number) => {
    setSelectedDocument(index === selectedDocument ? null : index);
    if (index !== selectedDocument && documentRevision[index]) {
      onDocumentSelect(documentRevision[index]);
    }
  };

  useEffect(() => {
    getDocumentsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cod_module]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [userData] = useState({
    title: '',
    description: '',
  });

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const [selectedDocumentFile, setSelectedDocumentFile] = useState<File | null>(
    null,
  );
  const [isModified, setIsModified] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
    trigger,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  useEffect(() => {
    const hasChanged =
      Object.keys(dirtyFields).length > 0 || selectedDocumentFile !== null;
    setIsModified(hasChanged);
  }, [dirtyFields, selectedDocumentFile]);

  const handleDocument = (file: File | null) => {
    if (file) {
      if (
        ![
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ].includes(file.type)
      ) {
        alert(
          'Por favor, selecciona un archivo de documento válido (PDF, DOC, DOCX, PPT, PPTX)',
        );
        return;
      }
      setSelectedDocumentFile(file);
      setIsModified(true);
      trigger();
    }
  };
  
  const onSubmit = async (values: any) => {
    try {
      const formData = new FormData();
      formData.append('name', nameCompany);
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('cod_project', cod_project.toString());
      formData.append('cod_module', cod_module.toString());
      if (selectedDocumentFile) {
        formData.append('workbooks', selectedDocumentFile);
      }
      await createDocumentRevision(formData);
      setIsEditing(false);
      reset(userData);
      onClose();
      getDocumentsInfo();
    } catch (error) {
      console.error('Error al crear el documento', error);
    }
  };

  const handleCancelDelete = () => {
    onClose();
    reset(userData);
  };

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        pt="30px"
        pb="20px"
        h="min-content"
        maxH={400}
        {...rest}
      >
        <Flex alignItems="center" mb={2}>
          <Text color={textColor} fontSize="2xl" fontWeight="700" mr={3}>
            Documentos
          </Text>

          <Button
            colorScheme="brandScheme"
            margin="0"
            borderRadius={50}
            padding={0}
            marginInlineEnd={0}
            onClick={() => onOpen()}
            leftIcon={
              <MdAdd style={{ margin: 0, marginInlineStart: '0.5rem' }} />
            }
          />
        </Flex>
        <Text color={textColor} fontWeight="700" mr={3}>
          Seleccione un documento
        </Text>
        <Flex
          position="relative"
          direction="column"
          maxH="100%"
          overflowY="auto"
        >
          <Flex
            position="absolute"
            left="32.5px"
            h="100%"
            w="2px"
            bg={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${lineColor}' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`}
            zIndex={1}
          />

          {documentRevision && documentRevision.length > 0 ? (
            documentRevision.map((doc, index) => (
              <ListDocuments
                key={index}
                mb={index !== documentRevision.length - 1 ? '80px' : '0px'}
                name={doc.title}
                date={doc.description}
                status={selectedDocument === index ? 'checked' : 'unchecked'}
                onClick={() => handleSelect(index)}
                cursor="pointer"
                icon={
                  <IconBox
                    h="66px"
                    w="66px"
                    bg={completeBg}
                    boxShadow={
                      selectedDocument === index
                        ? completeShadow
                        : incompleteShadow
                    }
                    icon={
                      <Icon
                        as={CgFileDocument}
                        color={brandColor}
                        h="32px"
                        w="32px"
                      />
                    }
                  />
                }
              />
            ))
          ) : (
            <Text>No hay documentos disponibles.</Text>
          )}
        </Flex>
      </Card>
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Crear documento de revisión</ModalHeader>
          <ModalBody>
            <SimpleGrid
              columns={{ sm: 1, md: 2 }}
              spacing={{ base: '20px', xl: '20px' }}
            >
              <FormControl isInvalid={!!errors.title}>
                <FormLabel
                  display="flex"
                  ms="10px"
                  fontSize="sm"
                  color={textColorPrimary}
                  fontWeight="bold"
                  _hover={{ cursor: 'pointer' }}
                >
                  Título
                </FormLabel>
                <Input
                  mb="25px"
                  id="title"
                  {...register('title')}
                  variant="main"
                  _placeholder={{
                    fontWeight: '400',
                    color: 'secondaryGray.600',
                  }}
                  onBlur={() => trigger('title')}
                />
                <Text color="red.500">{errors.title?.message}</Text>
              </FormControl>

              <FormControl isInvalid={!!errors.description}>
                <FormLabel
                  display="flex"
                  ms="10px"
                  fontSize="sm"
                  color={textColorPrimary}
                  fontWeight="bold"
                  _hover={{ cursor: 'pointer' }}
                >
                  Descripción
                </FormLabel>
                <Input
                  mb="25px"
                  id="description"
                  variant="main"
                  {...register('description')}
                  _placeholder={{
                    fontWeight: '400',
                    color: 'secondaryGray.600',
                  }}
                  onBlur={() => trigger('description')}
                />
                <Text color="red.500">{errors.description?.message}</Text>
              </FormControl>
            </SimpleGrid>

            <FormControl mt="10px" isDisabled={!isEditing}>
              <FormLabel htmlFor="document_file">Documento</FormLabel>
              <Upload
                pe="20px"
                pb={{ base: '100px', lg: '20px' }}
                isDocumentUpload={true}
                onDocumentSelect={handleDocument}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              color={'#fff'}
              bg={'navy.300'}
              _hover={{ color: '#fff', bg: 'navy' }}
              mr={'10px'}
              isDisabled={!isModified || !!errors.title || !!errors.description}
              isLoading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              Crear
            </Button>
            <Button
              color={'#fff'}
              bg="red.300"
              _hover={{ color: '#fff', bg: 'red' }}
              mr={'10px'}
              onClick={handleCancelDelete}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
