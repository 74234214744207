import React, { useEffect, useState } from 'react';
import {
  Tr,
  Td,
  Icon,
  Button,
  Menu,
  MenuButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  Text,
  ModalHeader,
  Table,
  Thead,
  Tbody,
  Th,
  Tr as TableRow,
  Td as TableData,
  Box,
  Checkbox,
} from '@chakra-ui/react';
import { CheckCircleIcon, AddIcon } from '@chakra-ui/icons';
import { FaUserPlus } from 'react-icons/fa';
import { AllModules } from 'types/Module';
import { Users } from 'types/Auth';
import { listUsersAvaiblablesModule } from 'services/Modules';
interface ModuleRowProps {
  module: AllModules;
  selectedUsersCompany: Users[];
  isModuleAssigned: (moduleCod: number) => boolean;
  getInfoCustomer: (
    codModule: number,
    requestType: string,
    codeUser: string,
  ) => void;
  codProject: number;
  nameCompany: string;
  onUsersSelected: (payload: {
    module: { cod_project: number; cod_module: number; cod_user: string }[];
  }) => void; // Updated type
}

const ModuleRow: React.FC<ModuleRowProps> = ({
  module,
  isModuleAssigned,
  getInfoCustomer,
  codProject,
  nameCompany,
  onUsersSelected,
}) => {
  const [usersAvailables, setUsersAvailables] = useState<Users[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<Users[]>([]);

  const {
    isOpen: isUsersOpen,
    onOpen: onUsersOpen,
    onClose: onUsersClose,
  } = useDisclosure();

  useEffect(() => {
    setSelectedUsers([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersAvailables]);

  const isModuleSelect = async (moduleSelect: number) => {
    try {
      const users = await listUsersAvaiblablesModule(
        nameCompany,
        codProject,
        moduleSelect,
      );
      setUsersAvailables(users);
      onUsersOpen();
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const toggleUserSelection = (user: Users) => {
    setSelectedUsers((prev) =>
      prev.some((selected) => selected.cod_user === user.cod_user)
        ? prev.filter((selected) => selected.cod_user !== user.cod_user)
        : [...prev, user],
    );
  };

  const handleConfirm = () => {
    const userAssignments = selectedUsers.map((selectedUser) => ({
      cod_project: codProject,
      cod_module: module.cod_module,
      cod_user: selectedUser.cod_user,
    }));

    const payload = { module: userAssignments };
    onUsersSelected(payload);
    onUsersClose();
  };

  const handleClose = () => {
    setSelectedUsers([]);
    onUsersClose();
  };

  return (
    <>
      <Tr key={module.cod_module}>
        <Td>{module.cod_module}</Td>
        <Td>{module.name_module}</Td>
        <Td>
          {isModuleAssigned(module.cod_module) ? (
            <Icon as={CheckCircleIcon} color="green.500" />
          ) : (
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<AddIcon />}
                onClick={() =>
                  getInfoCustomer(module.cod_module, 'module', 'null')
                }
              >
                Agregar módulo
              </MenuButton>
            </Menu>
          )}
        </Td>
        <Td>
          <Button
            rightIcon={<FaUserPlus />}
            isDisabled={!isModuleAssigned(module.cod_module)}
            onClick={() => isModuleSelect(module.cod_module)}
          >
            Agregar usuario
          </Button>
        </Td>
      </Tr>

      {/* Modal with a table */}
      <Modal isOpen={isUsersOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Usuarios Disponibles</ModalHeader>
          <ModalBody>
            <Box overflowX="auto" overflowY="auto" maxH="70vh">
              {/* Verificar si usersAvailables está vacío */}
              {usersAvailables.length > 0 ? (
                <Table variant="simple">
                  <Thead>
                    <TableRow>
                      <Th>Email</Th>
                      <Th>Rol</Th>
                      <Th>Seleccionar</Th>
                    </TableRow>
                  </Thead>
                  <Tbody>
                    {usersAvailables.map((user) => (
                      <TableRow key={user.cod_user}>
                        <TableData>{user.email}</TableData>
                        <TableData>{user.role_name}</TableData>
                        <TableData>
                          <Checkbox
                            isChecked={selectedUsers.some(
                              (selected) => selected.cod_user === user.cod_user,
                            )}
                            onChange={() => toggleUserSelection(user)}
                          />
                        </TableData>
                      </TableRow>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                // Mostrar mensaje cuando no haya usuarios disponibles
                <Text textAlign="center" fontSize="lg" color="red.500">
                  No hay usuarios disponibles
                </Text>
              )}
            </Box>
            <Flex justify="flex-end" mt={4}>
              <Button variant="ghost" mr={3} onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                colorScheme="brand"
                onClick={handleConfirm}
                isDisabled={usersAvailables.length === 0}
              >
                Confirmar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModuleRow;
