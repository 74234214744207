import { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  Tr,
  Box,
  Text,
  Tooltip,
  Td,
  TooltipProps,
  IconButton,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Portal,
} from '@chakra-ui/react';
import { RoleSection } from './Roles';
import { RenderField } from './Fields';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import useAuth from 'hooks/useAuth';

interface HtmlTooltipProps extends Omit<TooltipProps, 'label'> {
  label: ReactNode;
  children: ReactNode;
  rols: string;
}

const HtmlTooltip = ({ children, label, rols, ...props }: HtmlTooltipProps) => (
  <Tooltip
    label={
      <Box
        color={rols}
        maxW="220px"
        fontSize="12px"
        p="0.5rem"
        borderRadius="md"
      >
        {label}
      </Box>
    }
    placement="right"
    hasArrow
    style={{ width: '200px' }}
    {...props}
  >
    {children}
  </Tooltip>
);

type CombinedField = {
  cod_id: number;
  combinedData: {
    name: string;
    cod_config?: number;
    value: string | null;
    options?: { cod_option: number; value: string }[];
  }[];
  roles: {
    cod_permission: number;
    cod_roles: number;
    cod_id: number;
    cod_sheert: number;
    rol: string;
    name: string;
    value: string;
  }[];
};

type RowProps = {
  row: CombinedField;
  openRow: number | null;
  headers: string[];
  handleExpandClick: (cod_id: number) => void;
  handleRoleChange: (
    cod_id: number,
    roleName: string,
    cod_permission: number,
    value: string,
  ) => void;
  handleFieldsUpdated: (
    name: string,
    value: string,
    cod_config: number,
  ) => void;
  nameModule: string;
  rolUser: string;
  cardRef: any;
  codModule: number;
  codProject: number;
};

export const Row = ({
  row,
  openRow,
  headers,
  handleExpandClick,
  handleRoleChange,
  handleFieldsUpdated,
  nameModule,
  rolUser,
  cardRef,
  codModule,
  codProject,
}: RowProps) => {
  const isOpen = openRow === row.cod_id;
  const [fieldValues, setFieldValues] = useState(
    row.combinedData.reduce((acc, data) => {
      acc[data.name] = data.value;
      return acc;
    }, {} as Record<string, any>),
  );
  const [fieldName, setFieldName] = useState('No title');
  const { role } = useAuth();

  const handleFieldChange = useCallback((name: string, value: any) => {
    const field = row.combinedData.find((data) => data.name === name);
    const cod_config = field ? field.cod_config : undefined;

    setFieldValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    if (cod_config !== undefined) {
      handleFieldsUpdated(name, value, cod_config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bgModal = useColorModeValue('#ffffff', 'navy.800');

  useEffect(() => {
    if (role === 'superadmin') {
      const fieldName =
        row.combinedData.find(
          (data) =>
            data.name === 'Nombre del campo' ||
            data.name === 'ID de flujo de trabajo' ||
            data.name === 'Identificador' ||
            data.name === 'Nombre de campo' ||
            data.name === 'Nombre Etiqueta del sistema' ||
            data.name === 'Nombre de la escala de calificación' ||
            data.name === 'Nombre de la plantilla' ||
            data.name === 'Nombre del grupo ',
        )?.value || 'No title';
      setFieldName(fieldName);
    } else {
      const fieldName =
        row.combinedData.find(
          (data) =>
            data.name === 'Nombre del campo' ||
            data.name === 'ID de flujo de trabajo' ||
            data.name === 'Identificador' ||
            data.name === 'Nombre de campo' ||
            data.name === 'Nombre Etiqueta del sistema' ||
            data.name === 'Nombre de la escala de calificación' ||
            data.name === 'Nombre de la plantilla' ||
            data.name === 'Nombre del grupo ' ||
            data.name === 'Nombre en plantilla ' ||
            data.name === 'Nombre en plantilla' ||
            data.name === 'Autorizaciones de usuario' ||
            data.name === 'Nombre' ||
            data.name === 'Tipo de Campo ' ||
            data.name === 'Etiqueta de campo' ||
            data.name === 'Tipo de datos' ||
            data.name === 'Fuente' ||
            data.name === 'tipo de dato' ||
            data.name === 'Nombre en el flujo',
        )?.value || 'No title';
      setFieldName(fieldName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rols = useColorModeValue('white', 'gray.700');
  const inputVariant = useColorModeValue('gray.900', 'white');
  const bgHover = useColorModeValue(
    { bg: 'navy.50' },
    { bg: 'whiteAlpha.200' },
  );

  return (
    <>
      {role !== 'superadmin' && (
        <Tr sx={{ '& > *': { borderBottom: 'unset' } }} _hover={bgHover}>
          <Td
            scope="row"
            style={{
              border: '2px solid #b5b2b2',
              maxWidth: '150px',
              minWidth: '100%',
            }}
          >
            <HtmlTooltip
              label={<Text style={{ fontWeight: 'bold' }}>{fieldName}</Text>}
              rols={rols}
            >
              <Text
                style={{ display: 'flex', alignItems: 'left', cursor: 'help' }}
              >
                {fieldName}
                <IoInformationCircleOutline style={{ marginRight: 4 }} />
              </Text>
            </HtmlTooltip>
          </Td>
          {headers
            .filter(
              (header) =>
                ![
                  'Nombre del campo',
                  'ID de flujo de trabajo',
                  'Identificador',
                  'Nombre de campo',
                  'Nombre Etiqueta del sistema',
                  'Nombre de la escala de calificación',
                  'Nombre de la plantilla',
                  'Nombre del grupo ',
                  'Nombre en plantilla ',
                  'Nombre en plantilla',
                  'Autorizaciones de usuario',
                  'tipo de dato',
                  'Nombre',
                  'Etiqueta de campo',
                  'Tipo de Campo ',
                  'Tipo de dato',
                  'Nombre en el flujo',
                ].includes(header),
            )
            .map((header) => {
              const data = row.combinedData.find(
                (data) => data.name === header,
              );
              return (
                <Td
                  key={header}
                  align="left"
                  style={{
                    width: '220px',
                    border: '2px solid #b5b2b2',
                    color: rolUser === 'superadmin' ? inputVariant : 'inherit',
                    pointerEvents: rolUser === 'superadmin' ? 'none' : 'auto',
                  }}
                >
                  {data
                    ? RenderField(
                        data,
                        fieldValues,
                        handleFieldChange,
                        nameModule,
                        codModule,
                        codProject,
                      )
                    : 'N/A'}
                </Td>
              );
            })}
          {row.roles.length > 0 && (
            <Td
              sx={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
                border: '2px solid #b5b2b2',
              }}
            >
              <IconButton
                onClick={() => handleExpandClick(row.cod_id)}
                aria-label={isOpen ? 'Collapse' : 'Expand'}
              >
                {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </IconButton>
            </Td>
          )}
        </Tr>
      )}
      {role === 'superadmin' && (
        <Tr sx={{ '& > *': { borderBottom: 'unset' } }} _hover={bgHover}>
          <Td
            scope="row"
            style={{
              border: '2px solid #b5b2b2',
            }}
          >
            <HtmlTooltip
              label={<Text style={{ fontWeight: 'bold' }}>{fieldName}</Text>}
              rols={rols}
            >
              <Text
                style={{ display: 'flex', alignItems: 'left', cursor: 'help' }}
              >
                <IoInformationCircleOutline style={{ marginRight: 4 }} />
                {fieldName}
              </Text>
            </HtmlTooltip>
          </Td>
          {headers
            .filter(
              (header) =>
                ![
                  'Nombre del campo',
                  'ID de flujo de trabajo',
                  'Identificador',
                  'Nombre de campo',
                  'Nombre Etiqueta del sistema',
                  'Nombre de la escala de calificación',
                  'Nombre de la plantilla',
                  'Nombre del grupo ',
                ].includes(header),
            )
            .map((header) => {
              const data = row.combinedData.find(
                (data) => data.name === header,
              );
              return (
                <Td
                  key={header}
                  align="left"
                  style={{
                    width: '220px',
                    border: '2px solid #b5b2b2',
                    color: rolUser === 'superadmin' ? inputVariant : 'inherit',
                    pointerEvents: rolUser === 'superadmin' ? 'none' : 'auto',
                  }}
                >
                  {data
                    ? RenderField(
                        data,
                        fieldValues,
                        handleFieldChange,
                        nameModule,
                        codModule,
                        codProject,
                      )
                    : 'N/A'}
                </Td>
              );
            })}
          {row.roles.length > 0 && (
            <Td
              sx={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
                border: '2px solid #b5b2b2', 
              }}
              bgColor={rols}
            >
              <IconButton
                onClick={() => handleExpandClick(row.cod_id)}
                aria-label={isOpen ? 'Collapse' : 'Expand'}
              >
                {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </IconButton>
            </Td>
          )}
        </Tr>
      )}
      <Portal containerRef={cardRef}>
        <Modal
          isOpen={isOpen}
          onClose={() => handleExpandClick(row.cod_id)}
          size="2xl"
          scrollBehavior="inside"
          isCentered
        >
          <ModalOverlay />
          <ModalContent p={6} margin={5} width="max-content" bgColor={bgModal}>
            <ModalHeader>Roles de {fieldName}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <RoleSection
                roles={row.roles}
                handleRoleChange={handleRoleChange}
                cod_id={row.cod_id}
                rolUser={rolUser}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Portal>
    </>
  );
};

export default Row;
