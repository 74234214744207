import {
  Checkbox,
  Input,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { Role, UniquePermission } from '../../../../../../../types/Template';

interface RoleSectionProps {
  roles: Role[];
  cod_id: number;
  handleRoleChange: (
    cod_id: number,
    roleName: string,
    cod_permission: number,
    value: string,
  ) => void;
  rolUser: string;
}

interface CustomInputProps {
  value: string;
  handleRoleChange: (
    cod_id: number,
    permissionName: string,
    cod_permission: number,
    value: string,
  ) => void;
  cod_id: number;
  permission: UniquePermission;
  cod_permission: number;
  isDisabled: boolean;
}

const CustomInput: React.FC<CustomInputProps> = ({
  value,
  handleRoleChange,
  cod_id,
  permission,
  cod_permission,
  isDisabled,
}) => {
  const inputVariant = useColorModeValue('gray.900', 'white');
  return (
    <Input
      variant="outline"
      size="sm"
      value={value || ''}
      color={inputVariant}
      style={{
        pointerEvents: isDisabled ? 'none' : 'auto',
        opacity: isDisabled ? 0.2 : 1,
      }}
      onChange={(e) =>
        handleRoleChange(
          cod_id,
          permission.name,
          cod_permission,
          e.target.value,
        )
      }
      borderColor="gray.300"
      _focus={{ borderColor: 'blue.500' }}
    />
  );
};

export const RoleSection = ({
  roles,
  cod_id,
  handleRoleChange,
  rolUser,
}: RoleSectionProps) => {
  const rolePermissionsMap = roles.reduce((acc, curr) => {
    if (!acc[curr.rol]) {
      acc[curr.rol] = {};
    }
    acc[curr.rol][curr.name] = {
      value: curr.value,
      cod_permission: curr.cod_permission,
    };
    return acc;
  }, {} as Record<string, Record<string, { value: string; cod_permission: number }>>);

  const uniquePermissions: UniquePermission[] = Array.from(
    new Set(roles.map((permission) => permission.name)),
  ).map(
    (name) =>
      ({
        name,
        type: name === 'Etiqueta: Nombre personalizado' ? 'input' : 'checkbox',
      } as UniquePermission),
  );

  const rolesData = Object.keys(rolePermissionsMap).map((rol) => ({
    rol,
    permissions: rolePermissionsMap[rol],
  }));

  return (
    <Table
      style={{
        minWidth: 'max-content',
        width: '100%',
        maxWidth: 'max-content',
      }}
      variant="striped"
      mx={2}
      overflowX="scroll"
    >
      <Thead
        style={{
          border: '2px solid #b5b2b2',
          backgroundColor: '#7551FF',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: 'white',
        }}
      >
        <Tr>
          <Td
            style={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: 'white',
            }}
          >
            Rol
          </Td>
          {uniquePermissions.map((permission) => (
            <Td
              key={permission.name}
              align="center"
              style={{
                border: '2px solid #b5b2b2',
              }}
            >
              {permission.name}
            </Td>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {rolesData.map((role) => (
          <Tr key={role.rol}>
            <Td
              style={{
                border: '2px solid #b5b2b2',
              }}
            >
              {role.rol}
            </Td>
            {uniquePermissions.map((permission) => {
              const permissionData = role.permissions[permission.name] || {
                value: '',
                cod_permission: 0,
              };
              const { value, cod_permission } = permissionData;
              const isDisabled = value === '';
              const hasCustomLabelPermission =
                !!role.permissions['Etiqueta: Nombre personalizado'];

              return (
                <Td
                  key={permission.name}
                  align="center"
                  style={{
                    border: '2px solid #b5b2b2',
                    color: rolUser === 'superadmin' ? '#b5b2b2' : 'inherit',
                    pointerEvents: rolUser === 'superadmin' ? 'none' : 'auto',
                  }}
                >
                  {permission.type === 'checkbox' ? (
                    <Checkbox
                      isChecked={value === 'true'}
                      onChange={(e) => {
                        handleRoleChange(
                          cod_id,
                          permission.name,
                          cod_permission,
                          e.target.checked ? 'true' : 'false',
                        );
                      }}
                      isDisabled={isDisabled}
                      style={{
                        border: rolUser === 'customer' ? '#b5b2b2' : 'inherit',
                      }}
                    />
                  ) : (
                    permission.type === 'input' && (
                      <CustomInput
                        value={value || ''}
                        handleRoleChange={handleRoleChange}
                        cod_id={cod_id}
                        permission={permission}
                        cod_permission={cod_permission}
                        isDisabled={!hasCustomLabelPermission}
                      />
                    )
                  )}
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
