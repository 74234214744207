import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import Upload from 'components/dropzone/Upload';
import { useState } from 'react';
import { createCompanyCustomer } from 'services/Company';

export default function RegisterCompany() {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedBanner, setSelectedBanner] = useState<File | null>(null);
  const inputVariant = useColorModeValue('gray.900', 'white');

  const registerCompany = async (data: {
    name: string;
    description: string;
    image: File;
    banner: File;
  }) => {
    try {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('description', data.description);
      formData.append('image', data.image);
      formData.append('banner', data.banner);

      await createCompanyCustomer(formData);
    } catch (error) {
      console.error('Error registering company:', error);
    }
  };

  const handleImageChange = (file: File | null) => {
    if (file) {
      if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
        alert(
          'Por favor, selecciona un archivo de imagen válido (JPG, JPEG, PNG)',
        );
        return;
      }
      setSelectedImage(file);
    }
  };

  const handleBannerChange = (file: File | null) => {
    if (file) {
      if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
        alert(
          'Por favor, selecciona un archivo de imagen válido (JPG, JPEG, PNG)',
        );
        return;
      }
      setSelectedBanner(file);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get('name') as string;
    const description = formData.get('description') as string;

    if (selectedImage) {
      registerCompany({
        name,
        description,
        image: selectedImage,
        banner: selectedBanner,
      });
    } else {
      alert('Por favor, selecciona una imagen.');
    }
  };

  return (
    <>
      <Box>
        <Heading as="h5" size="md">
          Registra una empresa
        </Heading>
        <Text fontSize="sm">Ingresa el nombre de la empresa.</Text>
      </Box>

      <form onSubmit={handleSubmit}>
        <Flex direction={{ base: 'column', lg: 'row' }} align="center" gap={4}>
          <Box flex="1">
            <VStack spacing={4}>
              <FormControl id="name" isRequired>
                <FormLabel>Nombre de compañía</FormLabel>
                <Input
                  color={inputVariant}
                  name="name"
                  placeholder="Nombre de la compañía"
                />
              </FormControl>
              <FormControl id="description" isRequired>
                <FormLabel>Descripción</FormLabel>
                <Input
                  color={inputVariant}
                  name="description"
                  placeholder="Descripción de la compañía"
                />
              </FormControl>
              <FormControl id="image" isRequired>
                <FormLabel>Imagen de compañía</FormLabel>
                <Upload
                  pe="20px"
                  pb={{ base: '100px', lg: '20px' }}
                  onImageSelect={handleImageChange}
                />
              </FormControl>
              <FormControl id="banner" isRequired>
                <FormLabel>Banner de compañía</FormLabel>
                <Upload
                  pe="20px"
                  pb={{ base: '100px', lg: '20px' }}
                  onImageSelect={handleBannerChange}
                />
              </FormControl>
            </VStack>
          </Box>
        </Flex>
        <Box m={5}>
          <Button colorScheme="brandScheme" type="submit" w="full">
            Registrar
          </Button>
        </Box>
      </form>
    </>
  );
}
