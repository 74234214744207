// Chakra imports
import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import IconBox from 'components/icons/IconBox';
import { DashCurveDown, DashCurveUp } from 'components/icons/Icons';
import { FaUserCog } from 'react-icons/fa';
import { FiFileText } from 'react-icons/fi';

// Assets
import { MdCheckCircle } from 'react-icons/md';

export default function Conversion(props: { [x: string]: any }) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const dashColor = useColorModeValue('brand.500', 'whiteAlpha.500');
  const shadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset',
  );
  const completeShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'inset 0px 4px 4px rgba(255, 255, 255, 0.2)',
  );
  const boxBg = useColorModeValue(
    'white',
    'linear-gradient(180deg, #1F2A4F 0%, #18224D 50.63%, #111C44 100%)',
  );
  return (
    <Card p="30px" w="100%" {...rest} bgGradient={boxBg}>
      <Flex
        position="relative"
        direction={{ base: 'column', md: 'row' }}
        justifyContent="center"
      >
        <DashCurveUp
          left={{
            base: '154px',
            lg: '220px',
            xl: '275px',
            '2xl': '185px',
            '3xl': '275px',
          }}
          position="absolute"
          color={dashColor}
          w={{ base: '100px', lg: '132px' }}
          display={{ base: 'none', md: 'flex' }}
          h="22px"
        />
        <DashCurveDown
          right={{
            base: '154px',
            lg: '220px',
            xl: '275px',
            '2xl': '185px',
            '3xl': '275px',
          }}
          top="60px"
          position="absolute"
          color={dashColor}
          w={{ base: '100px', lg: '132px' }}
          display={{ base: 'none', md: 'flex' }}
          h="22px"
        />
        <Flex direction="column" align="center" justify="center">
          <IconBox
            mb="16px"
            w="100px"
            h="100px"
            bg={boxBg}
            shadow={shadow}
            boxShadow={completeShadow}
            icon={<Icon w="38px" h="38px" as={FiFileText} color={brandColor} />}
          />
          <Text
            textAlign="center"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            mb="10px"
          >
            Pre-Configuración
          </Text>
          <Text
            textAlign="center"
            color="secondaryGray.600"
            fontSize="md"
            fontWeight="400"
            maxW="278px"
          >
            Comience revisando los parámetros iniciales que le permitirán
            personalizar la experiencia de uso. Este paso asegura que todo esté
            listo para adaptarse a sus necesidades.
          </Text>
        </Flex>
        <Flex mx="60px" direction="column" align="center" justify="center">
          <IconBox
            mb="16px"
            w="100px"
            h="100px"
            bg={boxBg}
            shadow={shadow}
            boxShadow={completeShadow}
            icon={<Icon w="38px" h="38px" as={FaUserCog} color={brandColor} />}
          />
          <Text
            textAlign="center"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            mb="10px"
          >
            Configuración
          </Text>
          <Text
            textAlign="center"
            color="secondaryGray.600"
            fontSize="md"
            fontWeight="400"
            maxW="278px"
          >
            Complete los ajustes necesarios para personalizar las funciones
            clave de la plataforma. Aquí puede elegir las opciones que mejor se
            adapten a sus preferencias.
          </Text>
        </Flex>
        <Flex direction="column" align="center" justify="center">
          <IconBox
            mb="16px"
            w="100px"
            h="100px"
            bg={boxBg}
            shadow={shadow}
            boxShadow={completeShadow}
            icon={
              <Icon w="38px" h="38px" as={MdCheckCircle} color={brandColor} />
            }
          />
          <Text
            textAlign="center"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            mb="10px"
          >
            Revisión
          </Text>
          <Text
            textAlign="center"
            color="secondaryGray.600"
            fontSize="md"
            fontWeight="400"
            maxW="278px"
          >
            Revise todos los ajustes que ha realizado hasta el momento.
            Asegúrese de que la configuración sea correcta antes de proceder a
            la finalización del proceso.
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
